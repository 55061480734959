import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Screen from './Screen'

import { sesionManager } from './SesionManager'

import TextField from '@material-ui/core/TextField';

const sha1 = require('js-sha1');
const request = require('superagent');



const styles = theme => ({  

    mainDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    botConfirmar: {
      height: "27px",
      minWidth: "0px !important",
      width: "161px !important",
      justifyContent: "center",
    },    

    botSubir: {
      width: "40%",
      display: "flex",
      position: "relative",
      minWidth: "270px",
      margin: '8px auto',
      backgroundColor: "#00318c",
      fontFamily: "Titillium Web",
      color: "white",      
      alignItems: "center",
      cursor: "pointer",
    },

    textField: {
      width: 270,      
      color: "black",
      marginTop: "8px",
      height: "40px",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "flex-end",
      cursor: 'text',
    },

    cssLabel: {
      fontFamily: 'Titillium Web',  
      transform: "translate(8px, 18px) scale(1)",  
    },

    cssOutlinedInput: {
      fontFamily: 'Titillium Web',
      fontSize: "0.8em",    
    },

});

class RestorePassFirstStep extends Component {
 
  state = {    
    email: '',
    password: '',
    password_confirm: '',
    errorIn_nombre:false,
    errorIn_password:false,
    errorIn_password_confirm:false,
  };

  hitEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.recuperarPass();
      ev.preventDefault();
    }
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  closePopUp = () => {
  }

  checkLengthAnExistence(fieldName,len,currentError,errorInObj,strPrefix = " ") {

    console.log('field ',fieldName,'<',this.state[fieldName],'>')

    var retStr = ""

    if(this.state[fieldName].length>(len-1)) {
      console.log('Error por muchos en ' + fieldName)
      errorInObj["errorIn_"+fieldName]=true;
      retStr = "Lo sentimos, no podemos almacenar un" + strPrefix + fieldName + " con mas de "+(len-1)+" caracteres";
    }

    if(this.state[fieldName].length===0) {
      console.log('Error por 0 en ' + fieldName)
      errorInObj["errorIn_"+fieldName]=true;
      retStr = "Lo sentimos, todos los campos son obligatorios";
    }

    if(currentError!=="") return currentError;

    return retStr;
  }

  recuperarPass = () => {

    let score = this.state.email;
    
    let error = "";

    //Si no trae has es porque es el paso donde se pide el correo para enviar el link de recuperacion
    //pero si lo trae es porque ya es el paso donde se escribe la nueva contraseña
    if(!this.props.hash)
    {
      if(!sesionManager.validateEmail(score)) {
        error = "Lo sentimos, el correo electronico no es valido.";
      }
      
      if(score.length==0) {
        error = "Lo sentimos, debes diligenciar el correo.";
      }
      
      if(error!=="") {
        this.props.showAppError(error);
        return;      
      }
      
      this.setState({showLoader:true})

      request
        .post(process.env.REACT_APP_SERVER_URL+'ask_restore_pass.php')
        .query({correo:score})
        .end((err, res) => {
          this.setState({showLoader:false})
          if(err) {
            console.error('Error, posiblemente no haya conexión con el servidor', err);
            return null;
          }
          if(res && res.text) {
            console.log('Llega la respuesta',res.text)
            let jsonRes = JSON.parse(res.text);          
            console.log(jsonRes,jsonRes["success"],jsonRes.success)
            if(jsonRes.success==="false") {
              if(jsonRes.regError==="true") {
                this.props.showAppError(jsonRes.msg);
              } else {
                this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
              }
            }
            else {            
              this.props.showAppError("Verifica tu correo electrónico para continuar con el proceso");
              this.props.setCurrentScreen("register");
            }
          } else {
            console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
          }
        });  
    }
    else {
      let error = "";
      let errorInObj = {};

      error = this.checkLengthAnExistence("password",65,error,errorInObj);

      if(this.state.password!==this.state.password_confirm) {
        error = "La contraseña no coincide con su confirmación";
        errorInObj.errorIn_password_confirm = true;
      }
      
      if(error!=="") {
        this.props.showAppError(error);
        return;      
      }
      
      this.setState({showLoader:true})

      console.log('enviar ',this.props.hash,sha1(this.state.password))

      request
        .post(process.env.REACT_APP_SERVER_URL+'restore_pass.php')
        .query({hash:this.props.hash,pass:sha1(this.state.password)})
        .end((err, res) => {
          this.setState({showLoader:false})
          if(err) {
            console.error('Error, posiblemente no haya conexión con el servidor', err);
            return null;
          }
          if(res && res.text) {
            console.log('Llega la respuesta',res.text)
            let jsonRes = JSON.parse(res.text);          
            console.log(jsonRes,jsonRes["success"],jsonRes.success)
            if(jsonRes.success==="false") {
              if(jsonRes.regError==="true") {
                this.props.showAppError(jsonRes.msg);
              } else {
                this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
              }
            }
            else {
              this.props.showAppError("Tu contraseña ha sido cambiada exitosamente");
              this.props.setCurrentScreen("register");
            }
          } else {
            console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
          }
        });    
      }  
  }

  render() {
    
    const { classes,hash } = this.props;

    return (
      <Fragment>
        <Screen back={this.closePopUp} showLoader={this.state.showLoader} contentCss={{
          padding: "0 2%",
          width: "80%",
          margin: "10vh auto",
          overflowY: 'scroll',
        }}>
          <div className={classes.mainDiv}>
            <div id="botAtras" onClick={() => {
              this.props.setCurrentScreen("register")
            }}></div>
            <div id="imageCenter"></div>
            {!hash && <Fragment>
              <div id="imageCenterOlvido"></div>
              <TextField      
                key = 'email'
                error = {this.state.errorIn_email}
                id="outlined-serial-input"
                label={"Correo Electrónico"}
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.cssOutlinedInput,                      
                  },
                }}
                margin="none"
                variant="filled"
                value={this.state.email}
                onChange={this.handleChange('email')}              
                inputRef={input => (this['ref_restore1'] = input)}
                onClick={() => {
                  this['ref_restore1'].focus();
                }}      
              />
            </Fragment>}

            {hash && <Fragment>
              <div id="imageCenterOlvidoStep2"></div>
              <TextField
                key = 'password'
                error = {this.state.errorIn_password}
                id="outlined-password-input"
                label={"Contraseña"}
                //label={this.state.password!=''?null:"Contraseña"}
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.cssOutlinedInput,                                                         
                  },
                }}
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="filled"
                value={this.state.password}
                onChange={this.handleChange('password')}               
                inputRef={input => (this['ref_pass'] = input)}
                onClick={() => {
                  this['ref_pass'].focus();
                }}
                rowsMax={1}
              />
              <TextField
                key = 'password_confirm'
                error = {this.state.errorIn_password_confirm}
                id="outlined-password_confirm-input"
                label={"Confirmar contraseña"}
                //label={this.state.password!=''?null:"Contraseña"}
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.cssOutlinedInput,                                                         
                  },
                }}
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="filled"
                value={this.state.password_confirm}
                onChange={this.handleChange('password_confirm')}               
                inputRef={input => (this['ref_pass_confirm'] = input)}
                onClick={() => {
                  this['ref_pass_confirm'].focus();
                }}
                rowsMax={1}
              />
            </Fragment>}
            <div className={[classes.botConfirmar,classes.botSubir].join(' ')} style={{marginBottom: "15px"}} onClick={this.recuperarPass}>
              <span>Enviar</span>
            </div> 
      
          </div>
        </Screen>
      </Fragment>
    );
  }

}

export default (withStyles(styles,{ withTheme: true })(RestorePassFirstStep));