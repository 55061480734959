import React, { Component, Fragment } from "react";

import { withStyles } from "@material-ui/core/styles";

import Screen from "./Screen";
import Paginator from "./components/Paginator";

import { sesionManager } from "./SesionManager";

import TextField from "@material-ui/core/TextField";
import classNames from "classnames";

const sha1 = require("js-sha1");
const request = require("superagent");

const has2Rankings = false;

const styles = (theme) => ({
  mainDiv: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    height: "100%",
    width: "100%",
  },

  generalBotWeek: {
    top: "46px",
    width: "20%",
    color: "white",
    height: "27px",
    margin: "10px",
    cursor: "pointer",
    display: "flex",
    maxWidth: "280px",
    fontSize: "20px",
    fontFamily: "Titillium Web",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00318c",
    [theme.breakpoints.down(430)]: {
      fontSize: "14px",
    },
  },

  generalBot: {
    width: "50%",
    height: "41px",
    margin: "10px",
    maxWidth: "280px",
    fontFamily: "Titillium Web",
    backgroundColor: "#00318c",
    color: "white",
    position: "absolute",
    top: "46px",
    fontSize: "24px",
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    cursor: "pointer",
    [theme.breakpoints.down(980)]: {
      position: "unset",
      fontSize: "18px",
    },
  },

  botOn: {
    backgroundColor: "white",
    color: "#00318c",
  },

  titleRanking: {
    width: "calc( 100% - 40px )",
    display: "flex",
    padding: "20px",
    position: "relative",
    height: "52px",
    justifyContent: "center",
    [theme.breakpoints.down(980)]: {
      alignItems: "center",
      justifyContent: "center",
    },
  },

  rankingInfo: {
    height: "400px",
  },

  rankingPaginator: {},

  rankingContainer: {
    height: "100%",
    width: "100%",
    maxWidth: "1000px",
  },

  infoPenalty: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-evenly",
    paddingTop: "25px",
  },

  penaltyItem: {
    color: "black",
    backgroundColor: "white",
    display: "flex",
    width: "40%",
    minHeight: "27px",
    margin: "5px",
    alignItems: "center",
    paddingLeft: "10px",
    fontFamily: "Titillium Web",
    minWidth: 350,
  },

  penaltyItemScore: {
    color: "#00318c",
    flex: 2,
  },

  penaltyItemName: {
    flex: 9,
  },

  penaltyItemTime: {
    flex: 4,
    color: "#00318c",
  },

  generalPenaltyItem: {
    justifyContent: "center",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
  },

  titlePenaltyItem: {
    fontFamily: "Titillium Web",
  },

  specialPenaltyItem: {
    color: "white",
    backgroundColor: "#00318c",
    height: "37px",
  },

  buttonWeeks: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    marginTop: "13px",
  },

  divNo: {
    fontFamily: "Titillium Web",
    color: "white",
    fontSize: "30px",
  },
});

class Ranking extends Component {
  state = {
    showLoader: false,
    rankingType: "penalty",
    currentPagePenalty: 0,
    currentPage21: 0,
    dataPerPage: 10,
    selectedWeek: 0,
    dataRetrieved: false,
    dataToShow: [],
  };

  constructor() {
    super();
  }

  verifyDataPerPage = () => {
    //console.log('Entra con ',document.getElementById("rankingInfo").offsetWidth);
    if (document.getElementById("rankingInfo").offsetWidth < 740) {
      if (this.state.dataPerPage !== 10) {
        this.setState({ dataPerPage: 10 });
      }
    } else {
      if (this.state.dataPerPage !== 20) {
        //console.log('Llega a refrescar');
        this.setState({ dataPerPage: 20 });
      }
    }
  };

  componentDidMount() {
    var onResize = () => {
      if (document.getElementById("rankingInfo") === null) return;

      this.verifyDataPerPage();
    };

    if (window.addEventListener) {
      window.addEventListener("resize", onResize);
    } else if (window.attachEvent) {
      window.attachEvent("onresize", onResize);
    }

    this.verifyDataPerPage();

    this.getData(this.state.rankingType);
  }

  getData = (str) => {
    let juego = str === "penalty" ? 1 : 2;

    //console.log('Mostrar la info de ',juego,this.state.rankingType);

    if (sesionManager.rankingDataRetrieved[juego - 1]) {
      //monte la data
      this.setState({
        dataToShow: sesionManager.rankingData[juego - 1].data,
        dataRetrieved: true,
        showLoader: false,
      });
    } else {
      //se la tiene que traer
      this.setState({ dataRetrieved: false, showLoader: true });
      sesionManager.askAndProcessRanking(juego, false).then(() => {
        this.setState({
          dataToShow: sesionManager.rankingData[juego - 1].data,
          dataRetrieved: true,
          showLoader: false,
        });
      });
    }
  };

  closePopUp = () => {};

  selectRankingType = (str) => {
    this.setState({
      rankingType: str,
      currentPagePenalty: 0,
      currentPage21: 0,
    });
    this.getData(str);
  };

  selectWeek = (num) => {
    this.setState({ selectedWeek: num });
    if (num === 0) {
      this.getData("21");
    } else {
      this.setState({ dataToShow: [] });
    }
  };

  uppercase(str) {
    var array1 = str.split(" ");
    var newarray1 = [];

    for (var x = 0; x < array1.length; x++) {
      newarray1.push(
        array1[x].charAt(0).toUpperCase() + array1[x].slice(1).toLowerCase()
      );
    }
    return newarray1.join(" ");
  }

  onPageChange = (newPage) => {
    this.setState({ currentPagePenalty: newPage });
  };

  getFormatedTime = (time) => {
    if (isNaN(time)) return time;

    let newNum = time / 1000;

    newNum = newNum.toLocaleString("en-GB", { minimumFractionDigits: 3 });

    return newNum + " Secs.";
  };

  getPenaltyItem = (nombre, goles, tiempo, isSpecial, isTitle) => {
    const { classes } = this.props;

    let styleInfoPenalty = {};

    if (!has2Rankings) {
      styleInfoPenalty = {
        width: "80%",
      };
    }

    return (
      <div
        key={nombre}
        className={classNames(
          classes.penaltyItem,
          isSpecial && classes.specialPenaltyItem
        )}
        style={styleInfoPenalty}
      >
        <div
          className={classNames(
            classes.generalPenaltyItem,
            classes.penaltyItemName,
            isTitle && classes.titlePenaltyItem,
            isSpecial && classes.specialPenaltyItem
          )}
        >
          {nombre}
        </div>
        <div
          className={classNames(
            classes.generalPenaltyItem,
            classes.penaltyItemScore,
            isTitle && classes.titlePenaltyItem,
            isSpecial && classes.specialPenaltyItem
          )}
        >
          {goles}
        </div>
        <div
          className={classNames(
            classes.generalPenaltyItem,
            classes.penaltyItemTime,
            isTitle && classes.titlePenaltyItem,
            isSpecial && classes.specialPenaltyItem
          )}
        >
          {this.getFormatedTime(tiempo)}
        </div>
      </div>
    );
  };

  getMyPenaltyItem = () => {
    if (sesionManager.idUser === 0) {
      return null;
    }

    if (sesionManager.rankingData[0].myPos <= 10) {
      return null;
    }

    if (sesionManager.rankingData[0].myPos === "---") {
      return null;
    }

    let data =
      sesionManager.rankingData[0].data[sesionManager.rankingData[0].myPos - 1];

    return this.getPenaltyItem(
      sesionManager.rankingData[0].myPos + ". " + this.uppercase(data[1]),
      data[2],
      data[3],
      true
    );
  };

  render() {
    const { classes } = this.props;

    let dataRanking = this.state.dataToShow;
    /*dataRanking = [
    [45,"oscar martinez martinez",17],[52,"Roger Rodriguez",17],[46,"Edgar Sanabria Rodríguez ",16],[47,"Sandra Milena Piamba Gomez",16],[49,"Luis Alfonso Mora Marquez",16],[54,"Cristina Acosta Soto",16],[50,"Serafina Gomez",15],[67,"Santiago Prada Peña",15],[70,"Andrés Felipe Triana santa",15],[77,"Lilibeth Puerta Montes",15],[117,"andres sanchez",15],[60,"Allelit Murcia",14],[87,"Kateryne Cañon",14],[105,"Lorena Real",14],[108,"Rosana Alandetd",14],[129,"PAOLA ANDREA DIAZ BAHAMON",14],[53,"Natalia Isabel Piñeres polo ",13],[56,"Daniel Vides perez",13],[66,"Raquel Villadiego Rada",13],[78,"Juan Alvarez",13],[88,"Karen Cuacialpud ",13],[90,"emilio almanza",13],[109,"NILTON LOPEZ",13],
    ];*/

    let cPage =
      this.state.rankingType === "penalty"
        ? this.state.currentPagePenalty
        : this.state.currentPage21;

    let dataPerPage = this.state.dataPerPage;

    //console.log('Render con de a ','dataPerPage',dataPerPage,'cPage',cPage,'dataRanking.length',dataRanking.length,'dataPerPage',this.state.dataPerPage);

    if (!has2Rankings) dataPerPage = 10;

    let currentDataRanking = dataRanking.slice(
      cPage * dataPerPage,
      cPage * dataPerPage + dataPerPage
    );

    let styleInfoPenalty = {};

    if (!has2Rankings) {
      styleInfoPenalty = {
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
      };
    }

    return (
      <Fragment>
        <Screen
          back={this.closePopUp}
          showLoader={this.state.showLoader}
          contentCss={{
            padding: "0 2%",
            width: "80%",
            margin: "10vh auto",
            overflowY: "scroll",
          }}
        >
          <div className={classes.mainDiv}>
            <div id="imageCenter" />
            <div id="rankingContainer" className={classes.rankingContainer}>
              <div id="titleRanking" className={classes.titleRanking}>
                {has2Rankings && (
                  <div
                    className={classNames(
                      classes.generalBot,
                      this.state.rankingType === "penalty" && classes.botOn
                    )}
                    onClick={() => {
                      this.selectRankingType("penalty");
                    }}
                  >
                    PENALTY VIAJERO
                  </div>
                )}
                <div className="titleRanking text-white text-center">
                   <h1 className="my-0">Tabla de posiciones </h1>
                   <h3 className="my-0">Conoce qué tan próximo estás a la victoria</h3>
                </div>
                {has2Rankings && (
                  <div
                    className={classNames(
                      classes.generalBot,
                      this.state.rankingType === "21" && classes.botOn
                    )}
                    style={{ right: 0 }}
                    onClick={() => {
                      this.selectRankingType("21");
                    }}
                  >
                    HAZ LA 21
                  </div>
                )}
              </div>
              {this.state.rankingType === "penalty" && (
                <Fragment>
                  <div id="rankingInfo" className={classes.rankingInfo}>
                    <div
                      className={classes.infoPenalty}
                      style={styleInfoPenalty}
                    >
                      {this.getPenaltyItem(
                        "NOMBRE",
                        "GOLES",
                        "MOMENTO ÚLTIMO GOL",
                        false,
                        true
                      )}
                      {currentDataRanking.map((data, index) => {
                        return this.getPenaltyItem(
                          cPage * dataPerPage +
                            index +
                            1 +
                            ". " +
                            this.uppercase(data[1]),
                          data[2],
                          data[3],
                          data[0] === sesionManager.idUser
                        );
                      })}
                      {this.getMyPenaltyItem()}
                      {currentDataRanking.length === 0 && (
                        <div className={classes.divNo}>
                          No hay datos en el ranking seleccionado
                        </div>
                      )}
                    </div>
                  </div>
                  {has2Rankings && dataRanking.length > this.state.dataPerPage && (
                    <div style={{ width: "100%" }}>
                      <Paginator
                        key={"paginatorPenalty"}
                        totalItems={dataRanking.length}
                        itemsPerPage={this.state.dataPerPage}
                        onPageChange={this.onPageChange}
                      />
                    </div>
                  )}
                </Fragment>
              )}
              {this.state.rankingType === "21" && (
                <Fragment>
                  <div id="buttonWeeks" className={classes.buttonWeeks}>
                    {[0, 1, 2, 3, 4, 5, 6, 7].map((data, index) => {
                      return (
                        <div
                          key={data}
                          className={classNames(
                            classes.generalBotWeek,
                            this.state.selectedWeek === data && classes.botOn
                          )}
                          style={{ right: 0 }}
                          onClick={() => {
                            this.selectWeek(data);
                          }}
                        >
                          {data + 1} SEMANA
                        </div>
                      );
                    })}
                  </div>
                  <div id="rankingInfo" className={classes.rankingInfo21}>
                    <div className={classes.infoPenalty}>
                      {currentDataRanking.map((data, index) => {
                        return (
                          <div key={index} className={classes.penaltyItem}>
                            {cPage * dataPerPage +
                              index +
                              1 +
                              ". " +
                              this.uppercase(data[1])}{" "}
                            <div className={classes.penaltyItemScore}>
                              {data[2]}
                            </div>{" "}
                          </div>
                        );
                      })}
                      {currentDataRanking.length === 0 && (
                        <div className={classes.divNo}>
                          No hay datos en el ranking seleccionado
                        </div>
                      )}
                    </div>
                  </div>
                  {has2Rankings && dataRanking.length > this.state.dataPerPage && (
                    <div style={{ width: "100%" }}>
                      <Paginator
                        key={"paginator21"}
                        totalItems={dataRanking.length}
                        itemsPerPage={this.state.dataPerPage}
                        onPageChange={this.onPageChange}
                      />
                    </div>
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </Screen>
        <div
          id="botAtras"
          onClick={() => {
            this.props.backFromScreen();
          }}
        />
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Ranking);
