import React, { Component, Fragment } from "react";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import ImageUploader from "./thirdParty/react-images-upload";

import brown from "@material-ui/core/colors/brown";

import Screen from "./Screen";
import "./Registro.css";
import DeptosCiudades from "./DeptosCiudades";

import { sesionManager } from "./SesionManager";
import { BorderAll, BorderColorSharp } from "@material-ui/icons";

const sha1 = require("js-sha1");
const request = require("superagent");
const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const useBirthDay = false;
const useAddress = false;
const useDepto = false;

const allLugaresPosibles = require("./lugares");

//var textR = "ss";

const styles = (theme) => ({
  namediv: {
    color: "white",
    fontSize: "1.8em",
    fontFamily: "Titillium Web",
    textTransform: "uppercase",
    marginLeft: "34px",
  },

  botSubir: {
    color: "white",
    width: "91px",
    height: "30px",
    display: "flex",
    fontFamily: "Titillium Web",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00318c",
    marginLeft: "35px",
    marginTop: "5px",
    cursor: "pointer",
  },

  rankingHomeDiv2: {
    top: "197px !important",
  },

  scoreHomeDiv: {
    top: "112px !important",
  },

  scoreHomeDiv2: {
    top: "225px !important",
  },

  cssLabel: {
    /*color: "#f8e926",
      '&$cssFocused': {
        color: "#f8e926",
      },*/
    fontFamily: "Titillium Web",
    transform: "translate(8px, 18px) scale(1)",
  },

  cssLabelDense: {
    /*color: "#f8e926",
      '&$cssFocused': {
        color: "#f8e926",
      },*/
    fontFamily: "Titillium Web",
    transform: "translate(8px, 4px) scale(0.75)",
  },

  rankingHomeDiv: {
    color: "white",
    fontSize: "1.3em",
    fontFamily: "Titillium Web",
    textTransform: "uppercase",
    position: "absolute",
    top: "78px",
    width: "165px",
    right: "0px",
    textAlign: "center",
  },

  textField: {
    width: "40%",
    color: "black",
    minWidth: 270,
    marginTop: "8px",
    height: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "text",
  },

  cssOutlinedInput: {
    fontFamily: "Titillium Web",
    fontSize: "0.8em",
  },

  cssOutlinedSelect: {
    fontFamily: "Titillium Web",
  },

  formSubir: {
    marginLeft: 20,
    maxWidth: "270px",
  },

  sendButton: {
    width: "73px",
    color: "white",
    cursor: "pointer",
    display: "flex",
    position: "relative",
    marginTop: "8px",
    fontFamily: "Titillium Web",
    alignItems: "center",
    marginBottom: "8px",
    backgroundColor: "#00318c",
    height: "27px",
    display: "flex",
    alignItems: "center",
    paddingLeft: "8px",
    border: "1px solid #fff",
  },

  invisibleButton: {
    width: "50px",
    height: "50px",
    position: "absolute",
    right: "55px",
    top: "124px",
    cursor: "pointer",
  },

  mainDiv: {
    width: "100%",
  },
});

class Registro extends Component {
  state = {
    showLoader: false,
    referencia: "",
    ciudad: "",
    lugar: "",
    lugarCompleto: "",
    serial: "",
    errorIn_referencia: false,
    errorIn_lugar: false,
    errorIn_serial: false,
    picture: undefined,
    fileName: undefined,
    showSubir: false,
    allReferences: [],
    myScoreGame1: "---",
    myScoreGame2: "---",
    myPosGame1: "---",
    myPosGame2: "---",
    myTimeGame1: "---",
  };

  constructor() {
    super();
    window.removeEventListener("scoresRefreshed", this.refreshScores);
    window.addEventListener("scoresRefreshed", this.refreshScores);

    this.prefillLugares();
  }

  componentDidMount() {
    this.props.mainScreen.setRef("home", this);

    this.setState({ showLoader: true });
    request
      .post(process.env.REACT_APP_SERVER_URL + "get_referencias.php")
      .end((err, res) => {
        this.setState({ showLoader: false });
        if (err) {
          return null;
        }
        if (res && res.text) {
          let jsonRes = JSON.parse(res.text);
          if (jsonRes.success === "true") {
            this.setState({ allReferences: jsonRes.allRefs });
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });

    var arrPromises = [
      sesionManager.askAndProcessRanking(1),
      sesionManager.askAndProcessRanking(2),
    ];

    Promise.all(arrPromises).then(() => {
      this.setState({
        myScoreGame1: sesionManager.rankingData[0].myMaxScore,
        myScoreGame2: sesionManager.rankingData[1].myMaxScore,
        myPosGame1: sesionManager.rankingData[0].myPos,
        myPosGame2: sesionManager.rankingData[1].myPos,
        myTimeGame1: this.getFormatedTime(
          sesionManager.rankingData[0].myMinTime
        ),
      });
    });

    this.refreshAttemps();
  }

  getFormatedTime = (time) => {
    if (isNaN(time)) return time;

    let newNum = time / 1000;

    newNum = newNum.toLocaleString("en-GB", { minimumFractionDigits: 3 });

    return newNum + " Secs.";
  };

  refreshAttemps = () => {
    sesionManager.getAllAttempsInfo().then((info) => {
      window.windowManager.setAllAttempsInfo(info);
      // console.log('att..',info);
      // console.log('att2..',window.windowManager.allAttempsInfo);
    });
  };

  refreshScores = () => {
    this.setState({
      myScoreGame1: sesionManager.rankingData[0].myMaxScore,
      myScoreGame2: sesionManager.rankingData[1].myMaxScore,
      myPosGame1: sesionManager.rankingData[0].myPos,
      myPosGame2: sesionManager.rankingData[1].myPos,
      myTimeGame1: this.getFormatedTime(sesionManager.rankingData[0].myMinTime),
    });
  };

  checkLengthAnExistence(
    fieldName,
    len,
    currentError,
    errorInObj,
    strPrefix = " "
  ) {
    //console.log('field ',fieldName,'<',this.state[fieldName],'>')

    var retStr = "";

    if (this.state[fieldName].length > len - 1) {
      //console.log('Error por muchos en ' + fieldName)
      errorInObj["errorIn_" + fieldName] = true;
      retStr =
        "Lo sentimos, no podemos almacenar un" +
        strPrefix +
        fieldName +
        " con mas de " +
        (len - 1) +
        " caracteres";
    }

    if (this.state[fieldName].length === 0) {
      //console.log('Error por 0 en ' + fieldName)
      errorInObj["errorIn_" + fieldName] = true;
      retStr = "Lo sentimos, todos los campos son obligatorios";
    }

    if (currentError !== "") return currentError;

    return retStr;
  }

  hitEnter = (ev) => {
    if (ev.key === "Enter") {
      //this.registerUser();
      ev.preventDefault();
    }
  };

  handleChangeSelect = (event) => {
    var newState = { [event.target.name]: event.target.value };
    this.setState(newState);
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  trimObject = (obj) => {
    var keys = Object.keys(obj);

    for (var i = keys.length - 1; i >= 0; i--) {
      if (typeof obj[keys[i]] === "string") obj[keys[i]] = obj[keys[i]].trim();
    }

    return obj;
  };

  enviarNuevaFactura = () => {
    let error = "";
    let errorInObj = {};

    error = this.checkLengthAnExistence(
      "referencia",
      100,
      error,
      errorInObj,
      "a "
    );
    error = this.checkLengthAnExistence("lugar", 100, error, errorInObj);
    // error = this.checkLengthAnExistence("ciudad", 100, error, errorInObj);
    //error = this.checkLengthAnExistence("serial",65,error,errorInObj);
    

    if (this.state.referencia === "") {
      error =
        "Lo sentimos, debes seleccionar una Producto para poderte registrar";
    }

    if (this.state.picture === undefined) {
      error =
        "Debes subir la imagen de la factura de compra para poder continuar";
    }

    if (error !== "") {
      this.props.showAppError(error);
      this.setState(errorInObj);
      return;
    }

    this.setState({ showLoader: true });

    var params = Object.assign({}, this.state);
    params.lugar = params.lugar;
    params.picture = params.picture[0];
    params.idUser = sesionManager.idUser;
    params.cedula = sesionManager.cedula;
    params = this.trimObject(params);

    request
      .post(process.env.REACT_APP_SERVER_URL + "subir_factura.php")
      .type("form")
      .send(params)
      .end((err, res) => {
        this.setState({ showLoader: false });
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);
          //console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if (jsonRes.success === false) {
            if (jsonRes.regError === true) {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError(
                "Ha ocurrido un error al intentar registrarte, si el error persiste contactate al correo " +
                  process.env.REACT_APP_SUPORT_MAIL +
                  "\n\n[error: " +
                  jsonRes["msg"] +
                  "]"
              );
            }
          } else {
            this.refreshAttemps();
            this.props.showAppError(
              "Tu factura ha sido agregada satisfactoriamente.",
              () => {
                this.visibilidadSubir(false);
              }
            );
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  };

  subirFactura = (file, picture) => {
    //console.log('Pictures',picture,file);
    this.setState({
      picture: picture,
      fileName: file[0].name,
    });
  };

  visibilidadSubir = (mostrar) => {
    this.setState({
      showSubir: mostrar,
    });
  };

  openGame = (numGame) => {
    if (numGame == 2) return;
    this.props.openGame(numGame);
  };

  fillReferences() {
    var arr = [];

    // arr.push(
    //   <MenuItem key={0} value={""}>
    //     Producto
    //   </MenuItem>
    // );

    for (var i = 0; i < this.state.allReferences.length; i++) {
      arr.push(
        <MenuItem key={i + 1} value={this.state.allReferences[i]}>
          {this.state.allReferences[i]}
        </MenuItem>
      );
    }

    return arr;
  }

  fillLugares() {
    if (this.state.ciudad === undefined || this.state.ciudad === "") {
      return [];
    }

    var arr = [];

    // arr.push(
    //   <MenuItem key={0} value={""}>
    //     Lugar
    //   </MenuItem>
    // );

    for (var i = 0; i < this.allLugares[this.state.ciudad].length; i++) {
      arr.push(
        <MenuItem
          key={"lugar" + (i + 1)}
          value={this.allLugares[this.state.ciudad][i]}
        >
          {this.allLugares[this.state.ciudad][i]}
        </MenuItem>
      );
    }

    /*if(this.allLugares[this.state.ciudad].length>0) {
      if(this.state.lugar!==this.allLugares[this.state.ciudad][0]) {
        this.setState({lugar:this.allLugares[this.state.ciudad][0]})
      }
    }*/

    return arr;
  }

  prefillLugares() {
    this.allCiudades = [];
    this.allLugares = {}; //{Bogota:[Punto1,punto2]}

    // this.allCiudades.push(
    //   <MenuItem key={0} value={""}>
    //     Lugar
    //   </MenuItem>
    // );

    for (var i = 0; i < allLugaresPosibles.length; i++) {
      //console.log('Va a colocar ',allLugaresPosibles[i][0]);

      if (this.allLugares[allLugaresPosibles[i][0]] == undefined) {
        this.allCiudades.push(
          <MenuItem key={"ciudad" + (i + 1)} value={allLugaresPosibles[i][0]}>
            {allLugaresPosibles[i][0]}
          </MenuItem>
        );
        this.allLugares[allLugaresPosibles[i][0]] = [];
      }

      this.allLugares[allLugaresPosibles[i][0]].push(allLugaresPosibles[i][1]);

      //this.allLugares.push(<MenuItem key={i+1} value={allLugares[i]}>{allLugares[i]}</MenuItem>)
    }
  }

  render() {
    //console.log('Render para home');
    const { classes, userLogged, userLoggedLastname } = this.props;

    let classesFormSubir = ["containerHomeLeft"];

    if (this.state.showSubir) {
      classesFormSubir.push("containerSubirNoOculto");
    }

    ////console.log('Render con',classesFormSubir,this.state.showSubir);

    return (
      <Fragment>
        <Screen
          back={this.closePopUp}
          screen={"Home"}
          showLoader={this.state.showLoader}
          screenCss={{
            justifyContent: "flex-start",
            minHeight: "465px",
          }}
        >
          <div className={classes.mainDiv}>
            <div id="containerMainHomeUp">
              <div id="containerSubir" className={classesFormSubir.join(" ")}>
                <div
                  className={classes.invisibleButton}
                  onClick={() => {
                    this.visibilidadSubir(false);
                  }}
                />
                <form className={classes.formSubir}>
               
                  <TextField
                    key="lugar"
                    error={this.state.errorIn_referencia}
                    id="outlined-lugar-input"
                    label={"Lugar de compra"}
                    //label={this.state.lugar!=''?null:"Lugar de compra"}
                    className={classes.textField}
                    onKeyPress={this.hitEnter}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,
                        focused: classes.cssFocused,
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.cssOutlinedInput,
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    value={this.state.lugar}
                    onChange={this.handleChange("lugar")}
                    inputRef={(input) => (this["ref_lugarHome"] = input)}
                    onClick={() => {
                      this["ref_lugarHome"].focus();
                    }}
                    rowsMax={1}
                  />
                  {/* <TextField
                    style={{width:'100%'}}
                    id="standard-select-currency-native"
                    className={classes.textField}
                    select
                    label="Lugar"
                    value={this.state.lugar}
                    onChange={this.handleChange('lugar')}
                    SelectProps={{
                      MenuProps: {
                        className: classes.menu,
                      },
                    }}
                    InputLabelProps={{
                      classes: {
                        root: this.state.lugar===""?classes.cssLabel:classes.cssLabelDense,   
                        focused: classes.cssFocused,                   
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.cssOutlinedInput,                      
                      },
                    }}
                    margin="normal"
                    variant="filled"
                  >
                    {this.fillLugares()}
                  </TextField> */}
                  <div
                    style={{ width: "100%", position: "relative" }}
                    key="contRegistro"
                  >
                    <TextField
                      style={{ width: "100%" }}
                      id="standard-select-currency-native-referencia"
                      error={this.state.errorIn_lugar}
                      className={classes.textField}
                      select
                      label="Producto"
                      value={this.state.referencia}
                      onChange={this.handleChange("referencia")}
                      SelectProps={{
                        MenuProps: {
                          className: classes.menu,
                        },
                      }}
                      InputLabelProps={{
                        classes: {
                          root:
                            this.state.referencia === ""
                              ? classes.cssLabel
                              : classes.cssLabelDense,
                          focused: classes.cssFocused,
                        },
                      }}
                      InputProps={{
                        disableUnderline: true,
                        classes: {
                          root: classes.cssOutlinedInput,
                        },
                      }}
                      margin="normal"
                      variant="filled"
                    >
                      {this.fillReferences()}
                    </TextField>
                    {/* <div id="question">
                      <div id="question_info" />
                    </div> */}
                  </div>
                  {/*<TextField      
                    key = 'serial'
                    error = {this.state.errorIn_serial}
                    id="outlined-serial-input"
                    label={this.state.serial!=''?null:"Serial"}
                    className={classes.textField}
                    onKeyPress={this.hitEnter}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,   
                        focused: classes.cssFocused,                   
                      },
                    }}
                    InputProps={{
                      disableUnderline: true,
                      classes: {
                        root: classes.cssOutlinedInput,                      
                      },
                    }}
                    margin="none"
                    variant="filled"
                    value={this.state.serial}
                    onChange={this.handleChange('serial')}              
                  />*/}
                  <ImageUploader
                    withIcon={true}
                    buttonText="Choose images"
                    onChange={this.subirFactura}
                    imgExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
                    maxFileSize={5242880}
                    withPreview={true}
                    singleImage={true}
                    fileName={this.state.fileName}
                  />
                  <div
                    className={classes.sendButton}
                    onClick={this.enviarNuevaFactura}
                  >
                    Enviar
                  </div>
                </form>
              </div>
              <div id="homeLeft" className={"containerHomeLeft"}>
                <div id="imageBienvenidos" />
                <div className={classes.namediv}>{userLogged}</div>
                <div className={classes.namediv}>{userLoggedLastname}</div>
                <div id="imagePuntos" />
                <div
                  className={classes.botSubir}
                  onClick={() => {
                    this.visibilidadSubir(true);
                  }}
                >
                  Subir <div id="flechaDerecha" />
                </div>
              </div>
              <div id="homeRight" className={"containerHomeRight"}>
                <div id="imageRanking">
                  <div className={classes.rankingHomeDiv}>
                    {"Puesto " + this.state.myPosGame1}
                  </div>
                  <div
                    className={[classes.rankingHomeDiv].join(" ")}
                    style={{ top: 102 }}
                  >
                    {"Con " + this.state.myScoreGame1 + " goles"}
                  </div>
                  <div
                    className={[classes.rankingHomeDiv].join(" ")}
                    style={{ top: "137px", fontSize: "1em" }}
                  >
                    {"Tiempo último gol: " + this.state.myTimeGame1}
                  </div>
                  {/*<div className={[classes.rankingHomeDiv,classes.rankingHomeDiv2].join(' ')}>{"Puesto "+this.state.myPosGame2}</div>
                  <div className={[classes.rankingHomeDiv,classes.scoreHomeDiv2].join(' ')}>{"Con "+this.state.myScoreGame2+" puntos"}</div>*/}
                </div>
              </div>
              <div id="imageCenterHome" />

              <div className="titleHome text-center text-white">
                <h1 className="my-0">Bienvenido</h1>
                <h4 className="mt-0">
                  Entre más facturas ingreses, tendrás más posibilidades de
                  jugar y ganar
                </h4>
              </div>
            </div>
            <div id="divHomeButtons">
              <div id="imageCall" />
              <div
                id="imagePenalty"
                className={"containerHomeLeft"}
                onClick={() => {
                  this.openGame(1);
                }}
              >
                {/* <div id="labelJuegoPenalty">juega aquí</div> */}
              </div>
              {/* <div id="image21" className={"containerHomeRight"} onClick={() => {this.openGame(2)}}></div> */}
            </div>
          </div>
        </Screen>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Registro);
