import React, { Component,Fragment } from 'react';
import './AppError.css';

import Modal from '@material-ui/core/Modal';
import { withStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

const styles = theme => ({  
    modalWn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',         
    },         

    circular: {
        outline: "0px solid transparent",
        position: "absolute",
        left: "calc( 50% - 25px )",
        top: "calc( 50% )",
    },
});

class AppError extends Component {
 
  state = {
    opened: true,
    name: '',
    mail: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({opened:false});    
  };

  closePopUp = () => {
    this.props.onClose();
  }

  render() {
    
    const { classes,opened } = this.props;


    let realOpened = opened!==undefined?opened&&this.state.opened:this.state.opened
    
    return (
      <Fragment>
        <Modal
          open={true}          
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ root: classes.modalWn }}    
          disableAutoFocus={true}      
          onClose={this.handleClose}
        >
          <Grow in={realOpened} onExited={() => {this.closePopUp()}}>
            <div id="popupContainer">
              {/*<div id="buttonCloseAppError" onClick={this.handleClose} />*/}
              <div id="botCloseAppError" onClick={this.handleClose}></div>     
              <div id="brownBack2AppError">                
                <div id="contentAppError">                  
                  {this.props.str}
                </div>
              </div>
            </div>
          </Grow>
          {/*<CircularProgress size={50} className={classes.circular} />*/}
        </Modal>
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(AppError));