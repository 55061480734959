var loadScript = (scriptPath,callback) => {    
  const script = document.createElement("script");
  script.src = scriptPath;
  script.async = true;
  script.onload = () => {
    callback();
  };

  document.body.appendChild(script);
}

var loadScriptWithPromise = (scriptPath) => {   
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = scriptPath;
    script.async = true;
    script.onload = () => {
      resolve();
    };

    document.body.appendChild(script);  
  })  
}

export async function loadScriptsParallelWithDependencies(arrayScriptPaths) {      
  for (var i=0;i<arrayScriptPaths.length; i++) {
    var temp = await loadScriptsParallel(arrayScriptPaths[i]);    
  }

  return;  
}

export function loadScriptsSecuential(arrayScriptPaths,callback) {          
  if(arrayScriptPaths.length>0) {
    let ctrScript = arrayScriptPaths.shift();
    loadScript(ctrScript,() => {loadScriptsSecuential(arrayScriptPaths,callback)})
  }
  else {
    callback();
  }
}

export function loadScriptsParallel(arrayScriptPaths) {     
  return new Promise((resolve, reject) => {
    var arrPromises = arrayScriptPaths.map((srcPath) => {
        return new Promise((resolve, reject) => {
          loadScriptWithPromise(srcPath).then(() => {
            resolve();
          });
        })
    })

    Promise.all(arrPromises).then(() => {
      resolve();
    })
  })
}

