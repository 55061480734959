
class PhaserUtils {

  setAsButton(buttonObj, fnCallback) {
      buttonObj.setInteractive({useHandCursor: true})

      let colorTint = 0xAAAAAA;
      
      buttonObj.on('pointerover', () => {            
          buttonObj.setTint(colorTint,colorTint,colorTint,colorTint)            
      } )

      buttonObj.on('pointerout', () => {            
          buttonObj.clearTint()            
      } )

      buttonObj.on('pointerup', () => {            
          fnCallback()            
      } )
  }

  makeButtonBlink(obj,ingresarBut) {

  }

  

}

export default new PhaserUtils();
