var DeptosCiudades = {
  provicias: [
    "AZUAY",
    "BOLIVAR",
    "CAÑAR",
    "CARCHI",
    "COTOPAXI",
    "CHIMBORAZO",
    "EL ORO",
    "ESMERALDAS",
    "GALAPAGOS",
    "GUAYAS",
    "IMBABURA",
    "LOJA",
    "LOS RIOS",
    "MANABI",
    "MORONA SANTIAGO",
    "ORELLANA",
    "NAPO",
    "PASTAZA",
    "PICHINCHA",
    "TUNGURAHUA",
    "SANTA ELENA",
    "SANTO DOMINGO DE LOS TSACHILAS",
    "SUCUMBIOS",
    "ZAMORA CHINCHIPE",
  ],
};
export default DeptosCiudades;
