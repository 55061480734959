import React, { Component, Fragment } from "react";

import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import ImageUploader from "./thirdParty/react-images-upload";

import brown from "@material-ui/core/colors/brown";

import Screen from "./Screen";
import "./Registro.css";
import DeptosCiudades from "./DeptosCiudades";

import { sesionManager } from "./SesionManager";

const sha1 = require("js-sha1");
const request = require("superagent");
const meses = [
  "Enero",
  "Febrero",
  "Marzo",
  "Abril",
  "Mayo",
  "Junio",
  "Julio",
  "Agosto",
  "Septiembre",
  "Octubre",
  "Noviembre",
  "Diciembre",
];

const useBirthDay = false;
const useAddress = false;
const useDepto = false;

const allLugaresPosibles = require("./lugares");

//var textR = "ss";

const styles = (theme) => ({
  botConfirmar: {
    height: "27px",
    minWidth: "0px !important",
    width: "161px !important",
    justifyContent: "center",
  },

  botSubir: {
    width: "40%",
    display: "flex",
    position: "relative",
    minWidth: "270px",
    margin: "8px auto",
    backgroundColor: "#00318c",
    fontFamily: "Titillium Web",
    color: "white",
    alignItems: "center",
    cursor: "pointer",
  },
  form: {
    display: "flex",
    flex: "1",
    flexWrap: "wrap",
    justifyContent: "center",
  },
  butTerms: {
    color: "white",
    fontFamily: "Titillium Web",
    cursor: "pointer",
    textDecoration: "underline",
  },

  /*question: {
      color: "white",
      fontFamily: "Titillium Web",
      position: "absolute",
      top: "17px",
      fontSize: "1.2em",
      right: "-34px",
      border: "solid 2px white",
      width: "21px",
      textAlign: "center",
      borderRadius: "50%",
      height: "21px",
      cursor: "pointer",
    },*/

  textField: {
    width: "40%",
    color: "black",
    minWidth: 270,
    marginTop: "8px",
    height: "40px",
    backgroundColor: "white",
    display: "flex",
    justifyContent: "flex-end",
    cursor: "text",
    marginRight: "45px",
  },

  birthLabel: {
    width: "100%",
    color: "#f8e926",
    fontSize: "1rem",
    fontFamily: "Roboto, Helvetica, Arial, sans-serif",
    lineHeight: "1.1875em",
    margin: "14px 0px 5px 13px",
  },

  dateField: {
    width: "100%",
    margin: "14px 0 5px 0",
  },

  dataBirth: {
    display: "flex",
    flexWrap: "wrap",
    border: "1px solid #f8e926",
    borderRadius: "5px",
    margin: "14px 0 5px 0",
  },

  dayField: {
    flex: 2,
    margin: "14px 5px 5px 5px",
    minWidth: "65px",
  },

  monthField: {
    flex: 3,
    margin: "14px 5px 5px 5px",
    minWidth: "150px",
  },

  yearField: {
    flex: 2,
    margin: "14px 5px 5px 5px",
    minWidth: "90px",
  },

  cssLabel: {
    /*color: "#f8e926",
      '&$cssFocused': {
        color: "#f8e926",
      },*/
    fontFamily: "Titillium Web",
    transform: "translate(8px, 18px) scale(1)",
  },

  cssLabelDense: {
    /*color: "#f8e926",
      '&$cssFocused': {
        color: "#f8e926",
      },*/
    fontFamily: "Titillium Web",
    transform: "translate(8px, 4px) scale(0.75)",
  },

  cssFocused: {
    //color: "#f8e926",
  },

  cssOutlinedInput: {
    fontFamily: "Titillium Web",
  },

  notchedOutline: {
    borderColor: "#f8e926",
  },

  cssCheck: {
    color: "white !important",
  },

  buttonReg: {
    margin: theme.spacing.unit,
    backgroundColor: "#109cea",
    borderRadius: 30,
  },

  terms: {
    height: "60vh",
    overflowY: "scroll",
    color: "white",
    paddingRight: "15px",
    textAlign: "center",
  },

  divFollowInsta: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "20px 0px",
    cursor: "pointer",
  },

  labelInsta: {
    width: "60%",
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    color: "#f8e926",
    textDecoration: "underline",
    textAlign: "center",
  },

  regField: {
    fontFamily: "'password_dotsregular'",
    fontSize: "1em",
    color: "#f8e926",
  },

  mainDiv: {
    overflowY: "auto",
    maxWidth: "900px",
    marginBottom: "120px",
  },

  disableInput: {
    display: "none",
  },
});

class Registro extends Component {
  /*state = {    
    nombre: 'Carlos Andres',
    apellido: 'Beltrán Gomez',
    dia: 11,
    mes: 11,
    anio: 1984,
    cedula: '18402524',
    direccion: 'Av 19',
    departamento: 'Quindio',
    ciudad: 'Armenia',
    telefono: '3136597156',
    correo: 'cbeltrangomez@gmail.com',
    referencia: '60UK6200PDA.AWC-2018',
    lugar: 'Unicentro',
    serial: '123456789',
    password: '123456',
    password_confirm: '123456',
    maxDays: 31,
    showLoader:false,
    termsChecked:true, 
    showCitySelect:false,
    errorIn_nombre:false,
    errorIn_apellido:false,
    errorIn_birthdate:false,
    errorIn_cedula:false,
    errorIn_direccion:false,
    errorIn_departamento:false,
    errorIn_ciudad:false,
    errorIn_telefono:false,
    errorIn_correo:false,
    errorIn_password:false,
    errorIn_password_confirm:false,
    errorIn_referencia:false,
    errorIn_lugar:false,
    errorIn_serial:false,
    picture:undefined,
    fileName:undefined,
    allReferences:[],
  };//*/

  state = {
    nombre: "",
    apellido: "",
    dia: 1,
    mes: 1,
    anio: 2000,
    cedula: "",
    direccion: "",
    departamento: "",
    ciudad: "",
    telefono: "",
    correo: "",
    referencia: "",
    lugar: "",
    ciudad: "",
    serial: "",
    password: "",
    password_confirm: "",
    maxDays: 31,
    showLoader: false,
    termsChecked: false,
    hasProduct: false,
    showCitySelect: false,
    errorIn_nombre: false,
    errorIn_apellido: false,
    errorIn_birthdate: false,
    errorIn_cedula: false,
    errorIn_direccion: false,
    errorIn_departamento: false,
    errorIn_ciudad: false,
    errorIn_telefono: false,
    errorIn_correo: false,
    errorIn_password: false,
    errorIn_password_confirm: false,
    errorIn_referencia: false,
    errorIn_lugar: false,
    errorIn_serial: false,
    picture: undefined,
    fileName: undefined,
    allReferences: [],
  }; //*/

  constructor() {
    super();
    this.prefillLugares();
    this.selectHint = "";
  }

  componentDidMount() {
    this.setState({ showLoader: true });
    request
      .post(process.env.REACT_APP_SERVER_URL + "get_referencias.php")
      .then((res) => {
        this.setState({ showLoader: false });
        /*if(err) {
          this.props.showAppError('Error, posiblemente el servidor no este respondiendo correctamente');
          return null;
        }*/
        if (res && res.text) {
          let jsonRes = JSON.parse(res.text);
          if (jsonRes.success === "true") {
            this.setState({ allReferences: jsonRes.allRefs });
          }
        } else {
          //console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);
        }
      })
      .catch((err) => {
        this.setState({ showLoader: false });
        this.props.showAppError(
          "Error, posiblemente el servidor no este respondiendo correctamente"
        );
      });
  }

  diasEnUnMes = (mes, año) => {
    return new Date(año, mes, 0).getDate();
  };

  handleChangeSelect = (event) => {
    //console.log(event.target.name,'->',event.target.value);
    var newState = { [event.target.name]: event.target.value };

    if (event.target.name === "departamento") {
      newState.showCitySelect = true;
    }

    this.setState(newState);
  };

  handleChangeSelectMes = (event) => {
    var newState = { [event.target.name]: event.target.value };
    var maxDay = 0;

    if (event.target.name === "mes") {
      maxDay = this.diasEnUnMes(event.target.value, this.state.anio);
    } else {
      maxDay = this.diasEnUnMes(this.state.mes, event.target.value);
    }

    if (this.state.dia > maxDay) {
      newState["dia"] = maxDay;
    }

    newState["maxDays"] = maxDay;

    this.setState(newState);
  };

  handleChangeCheck = (name) => (event) => {
    this.setState({
      termsChecked: event.target.checked,
    });
  };

  handleChangeHasProduct = (name) => (event) => {
    console.log("pasa");
    this.setState({
      hasProduct: event.target.checked,
    });
  };

  closePopUp = () => {
    this.props.back();
  };

  showTerms = (contentType) => {
    window.open(
      "https://masqueuntv.com/assets/TerminosYCondiciones__LG.pdf",
      "_blank"
    );
  };

  checkLengthAnExistence(
    fieldName,
    len,
    currentError,
    errorInObj,
    strPrefix = " "
  ) {
    //console.log('field ',fieldName,'<',this.state[fieldName],'>')

    var retStr = "";

    if (this.state[fieldName].length > len - 1) {
      //console.log('Error por muchos en ' + fieldName)
      errorInObj["errorIn_" + fieldName] = true;
      retStr =
        "Lo sentimos, no podemos almacenar un" +
        strPrefix +
        fieldName +
        " con mas de " +
        (len - 1) +
        " caracteres";
    }

    if (this.state[fieldName].length === 0) {
      //console.log('Error por 0 en ' + fieldName)
      errorInObj["errorIn_" + fieldName] = true;
      retStr = "Lo sentimos, todos los campos son obligatorios";
    }

    if (currentError !== "") return currentError;

    return retStr;
  }

  fillNum = (num) => {
    return (num < 10 ? "0" : "") + num;
  };

  trimObject = (obj) => {
    var keys = Object.keys(obj);

    for (var i = keys.length - 1; i >= 0; i--) {
      if (typeof obj[keys[i]] === "string") obj[keys[i]] = obj[keys[i]].trim();
    }

    return obj;
  };

  registerUser = () => {
    let nombre = this.state.nombre;
    let correo = this.state.correo;
    //let apellido = this.state.apellido;
    //let cedula = this.state.cedula;
    //let direccion = this.state.direccion;
    //let departamento = this.state.departamento;
    //let ciudad = this.state.ciudad;
    //let telefono = this.state.telefono;
    let pass = this.state.password;
    let termsChecked = this.state.termsChecked;
    let hasProduct = this.state.hasProduct;

    let birthdate;

    if (useBirthDay) {
      birthdate =
        this.state.anio +
        "-" +
        this.fillNum(this.state.mes) +
        "-" +
        this.fillNum(this.state.dia) +
        "T00:00:00.000Z";
    }

    let error = "";
    let errorInObj = {};

    error = this.checkLengthAnExistence("nombre", 255, error, errorInObj);
    error = this.checkLengthAnExistence("apellido", 255, error, errorInObj);
    error = this.checkLengthAnExistence("cedula", 21, error, errorInObj, "a ");
    if (useAddress)
      error = this.checkLengthAnExistence(
        "direccion",
        255,
        error,
        errorInObj,
        "a "
      );
    error = this.checkLengthAnExistence("telefono", 40, error, errorInObj);

    if (useDepto) {
      error = this.checkLengthAnExistence("ciudad", 40, error, errorInObj);
      error = this.checkLengthAnExistence(
        "departamento",
        40,
        error,
        errorInObj
      );
    }

    error = this.checkLengthAnExistence("correo", 100, error, errorInObj);
    error = this.checkLengthAnExistence("password", 65, error, errorInObj);

    error = this.checkLengthAnExistence("ciudad", 100, error, errorInObj);

    if (hasProduct) {
      error = this.checkLengthAnExistence("lugar", 100, error, errorInObj);
    }

    //error = this.checkLengthAnExistence("serial",65,error,errorInObj);

    //console.log('st',this.state)
    //console.log('st2',Object.assign({},this.state,{pass:sha1(pass)}))
    //console.log(errorInObj)

    if (hasProduct && this.state.referencia === "") {
      error =
        "Lo sentimos, debes seleccionar un Producto para poderte registrar";
    }

    if (this.state.password !== this.state.password_confirm) {
      error = "La contraseña no coincide con su confirmación";
      errorInObj.errorIn_password_confirm = true;
    }

    if (!sesionManager.validateEmail(correo)) {
      error = "Lo sentimos, el correo electronico no es valido";
      errorInObj.errorIn_correo = true;
    }

    //console.log('Llega a reg con pic<',this.state.picture,'>');
    if (
      hasProduct &&
      (this.state.picture === undefined || this.state.picture === "")
    ) {
      error =
        "Debes subir la imagen de la factura de compra para poder continuar";
    }

    if (!termsChecked) {
      error =
        "Lo sentimos, debes aceptar los términos y condiciones y la política de tratamiento de datos";
    }

    if (error !== "") {
      this.props.showAppError(error);
      this.setState(errorInObj);
      return;
    }

    this.setState({ showLoader: true });

    var params = Object.assign({}, this.state, { pass: sha1(pass) });
    delete params.password_confirm;

    if (useBirthDay) params.birthdate = birthdate;

    params.picture =
      params.picture != undefined ? params.picture[0] : false;


    params = this.trimObject(params);

    request
      .post(process.env.REACT_APP_SERVER_URL + "create_user.php")
      .type("form")
      .send(params)
      .end((err, res) => {
        this.setState({ showLoader: false });
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);
          //console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if (jsonRes.success === false) {
            if (jsonRes.regError === true) {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError(
                "Ha ocurrido un error al intentar registrarte, si el error persiste contactate al correo " +
                  process.env.REACT_APP_SUPORT_MAIL +
                  "\n\n[error: " +
                  jsonRes["msg"] +
                  "]"
              );
            }
          } else {
            sesionManager.setCookie("mail", correo);
            sesionManager.setCookie("hash", sha1(pass));

            //Si luego de crear recibe el flag need_user_email_confirm en true entonces solo lo notifica
            if (jsonRes.need_user_email_confirm === true) {
              this.props.showAppError(
                "Hola, " +
                  nombre +
                  "\n tu cuenta ha sido creada exitosamente, revisa tu correo para poder confirmarla antes de continuar",
                () => {
                  this.closePopUp();
                }
              );
            }
            //si no entonces coloca los datos de la sesion
            else {
              sesionManager.setUserData(
                nombre,
                jsonRes.id,
                -1,
                0,
                jsonRes.allow_play_without_code
              );
              this.props.showAppError(
                "Hola, " + nombre + "\n tu cuenta ha sido creada exitosamente.",
                () => {
                  this.closePopUp();
                }
              );
            }
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  };

  hitEnter = (ev) => {
    if (ev.key === "Enter") {
      this.registerUser();
      ev.preventDefault();
    }
  };

  handleDateChange = (date) => {
    this.setState({ birthdate: date.toISOString() });
  };

  fillDias() {
    var arr = [];

    for (var i = 1; i <= this.state.maxDays; i++) {
      arr.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return arr;
  }

  fillMeses() {
    var arr = [];

    for (var i = 1; i <= 12; i++) {
      arr.push(
        <MenuItem key={i} value={i}>
          {meses[i - 1]}
        </MenuItem>
      );
    }

    return arr;
  }

  fillAnios() {
    var arr = [];

    for (var i = 1950; i <= 2018; i++) {
      arr.push(
        <MenuItem key={i} value={i}>
          {i}
        </MenuItem>
      );
    }

    return arr;
  }

  fillLugares() {
    if (this.state.ciudad === undefined || this.state.ciudad === "") {
      return [];
    }

    var arr = [];

    arr.push(
      <MenuItem key={0} value={""}>
        Lugar
      </MenuItem>
    );

    for (var i = 0; i < this.allLugares[this.state.ciudad].length; i++) {
      arr.push(
        <MenuItem
          key={"lugar" + (i + 1)}
          value={this.allLugares[this.state.ciudad][i]}
        >
          {this.allLugares[this.state.ciudad][i]}
        </MenuItem>
      );
    }

    /*if(this.allLugares[this.state.ciudad].length>0) {
      if(this.state.lugar!==this.allLugares[this.state.ciudad][0]) {
        this.setState({lugar:this.allLugares[this.state.ciudad][0]})
      }
    }*/

    return arr;
  }

  prefillLugares() {
    this.allCiudades = [];
    this.allLugares = {}; //{Bogota:[Punto1,punto2]}

    this.allCiudades.push(
      <MenuItem key={0} value={""}>
        Lugar
      </MenuItem>
    );

    for (var i = 0; i < allLugaresPosibles.length; i++) {
      //console.log('Va a colocar ',allLugaresPosibles[i][0]);

      if (this.allLugares[allLugaresPosibles[i][0]] == undefined) {
        this.allCiudades.push(
          <MenuItem
            id={"ciudad" + (i + 1)}
            key={"ciudad" + (i + 1)}
            value={allLugaresPosibles[i][0]}
          >
            {allLugaresPosibles[i][0]}
          </MenuItem>
        );
        this.allLugares[allLugaresPosibles[i][0]] = [];
      }

      this.allLugares[allLugaresPosibles[i][0]].push(allLugaresPosibles[i][1]);

      //this.allLugares.push(<MenuItem key={i+1} value={allLugares[i]}>{allLugares[i]}</MenuItem>)
    }
  }

  fillReferences() {
    var arr = [];

    arr.push(
      <MenuItem key={0} value={""}>
        Referencia
      </MenuItem>
    );

    for (var i = 0; i < this.state.allReferences.length; i++) {
      arr.push(
        <MenuItem key={i + 1} value={this.state.allReferences[i]}>
          {this.state.allReferences[i]}
        </MenuItem>
      );
    }

    return arr;
  }

  fillDeptos() {
    var arr = [];

    for (var key in DeptosCiudades) {
      arr.push(
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      );
    }

    return arr;
  }

  fillCiudades() {
    var arr = [];

    var data = DeptosCiudades[this.state.departamento];

    for (var i = 0; i < data.length; i++) {
      var key = data[i];
      arr.push(
        <MenuItem key={key} value={key}>
          {key}
        </MenuItem>
      );
    }

    return arr;
  }

  subirFactura = (file, picture) => {
    //console.log('Pictures',picture,file);
    this.setState({
      picture: picture,
      fileName: file[0].name,
    });
  };

  handleChange = (name) => (event) => {
    //console.log('Tipea');
    //console.log(event);
    //console.log(event.target.value);

    if (name === "password") {
      //console.log('this._my_field',this._my_field)
      //this._my_field.innerText = "fdsfd"
    }

    if (name === "cedula") {
      if (
        /^\d+$/.test(event.target.value) === false &&
        event.target.value !== ""
      ) {
        return;
      }
    }

    this.setState({
      [name]: event.target.value,
    });
  };

  pressSelectCiudad = (event) => {
    /*var letra = event.key.toUpperCase();

    console.log('Enter key pressed',event.key);

    if(letra.match(/[A-Z]/i)) {
      this.selectHint+=letra;

      Object.keys(this.allLugares).forEach((elem) => {
        
        console.log(elem,'-',this.selectHint,'es',elem.indexOf(this.selectHint));
        if(elem.indexOf(this.selectHint)==0) {
          this.setState({ciudad:elem});
          return;
        }        
      })
    }*/
    /*    if (e.key === 'Enter') {
              // write your functionality here
            }

      if (event.ctrlKey && event.keyCode == '13')
          this.sendMessage();*/
  };

  getField(typeField, classes) {
    switch (typeField) {
      case "nombre":
        return (
          <TextField
            key="nombre"
            error={this.state.errorIn_nombre}
            id="outlined-name-input"
            className={classes.textField}
            onKeyPress={this.hitEnter}
            //label={this.state.nombre!=''?null:'Nombres'}
            label={"Nombres"}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
            value={this.state.nombre}
            onChange={this.handleChange("nombre")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "correo":
        return (
          <TextField
            key="correo"
            error={this.state.errorIn_correo}
            id="outlined-email-input"
            label={"E-mail"}
            //label={this.state.correo!=''?null:'E-mail'}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            type="email"
            name="email"
            autoComplete="email"
            margin="normal"
            variant="filled"
            value={this.state.correo}
            onChange={this.handleChange("correo")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "apellido":
        return (
          <TextField
            key="apellido"
            error={this.state.errorIn_apellido}
            id="outlined-apellido-input"
            label={"Apellidos"}
            //label={this.state.apellido!=''?null:"Apellidos"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
            value={this.state.apellido}
            onChange={this.handleChange("apellido")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "password":
        return (
          <TextField
            key="password"
            error={this.state.errorIn_password}
            id="outlined-password-input"
            label={"Contraseña"}
            //label={this.state.password!=''?null:"Contraseña"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            type="password"
            autoComplete="current-password"
            margin="normal"
            variant="filled"
            value={this.state.password}
            onChange={this.handleChange("password")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "password_confirm":
        return (
          <TextField
            key="password_confirm"
            error={this.state.errorIn_password_confirm}
            id="outlined-password_confirm-input"
            label={"Confirmar contraseña"}
            //label={this.state.password!=''?null:"Contraseña"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            type="password"
            autoComplete="current-password"
            margin="normal"
            variant="filled"
            value={this.state.password_confirm}
            onChange={this.handleChange("password_confirm")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "cedula":
        return (
          <TextField
            key="cedula"
            error={this.state.errorIn_cedula}
            id="outlined-cedula-input"
            label={"Cédula"}
            helperText="Ingrese número sin espacios, puntos o comas."
            //label={this.state.cedula!=''?null:"Cédula"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
            value={this.state.cedula}
            onChange={this.handleChange("cedula")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "ciudadCompra":
        return (
          <TextField
            key="select-ciudad"
            style={{ width: "40%", position: "relative", marginRight: "45px" }}
            id="standard-select-currency-native-provincia"
            className={classes.textField}
            select
            onKeyPress={this.pressSelectCiudad}
            label="Provincia"
            value={this.state.ciudad}
            onChange={this.handleChange("ciudad")}
            SelectProps={{
              MenuProps: {
                className: classes.menu,
              },
            }}
            InputLabelProps={{
              classes: {
                root:
                  this.state.ciudad === ""
                    ? classes.cssLabel
                    : classes.cssLabelDense,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
          >
            {this.allCiudades}
          </TextField>
        );

      // case 'lugar': return(<TextField
      //               key="select-lugar"
      //               style={{width: "40%", position: "relative", marginRight: "45px"}}
      //               id="standard-select-currency-native"
      //               className={classes.textField}
      //               select
      //               label="Lugar"
      //               value={this.state.lugar}
      //               onChange={this.handleChange('lugar')}
      //               SelectProps={{
      //                 MenuProps: {
      //                   className: classes.menu,
      //                 },
      //               }}
      //               InputLabelProps={{
      //                 classes: {
      //                   root: this.state.lugar===""?classes.cssLabel:classes.cssLabelDense,
      //                   focused: classes.cssFocused,
      //                 },
      //               }}
      //               InputProps={{
      //                 disableUnderline: true,
      //                 classes: {
      //                   root: classes.cssOutlinedInput,
      //                 },
      //               }}
      //               margin="normal"
      //               variant="filled"
      //             >
      //               {this.fillLugares()}
      //             </TextField>);

      /*case 'referencia': return(<TextField      
              key = 'referencia'
              error = {this.state.errorIn_referencia}
              id="outlined-ref-input"
              label={this.state.referencia!=''?null:"Referencia TV"}
              className={classes.textField}
              onKeyPress={this.hitEnter}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,   
                  focused: classes.cssFocused,                   
                },
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.cssOutlinedInput,                      
                },
              }}
              margin="normal"
              variant="filled"
              value={this.state.referencia}
              on
              //multiline={true} Change={this.handleChange('referencia')}              
              rowsMax={1}
            />);*/

      case "hasProduct":
        //console.log('GetReg<'+this.state.referencia+'>');
        return (
          <div id="labelAcepto" key="hasProduct">
            <Checkbox
              checked={this.state.hasProduct}
              onChange={this.handleChangeHasProduct()}
              onKeyPress={this.hitEnter}
              value="hasProduct"
              classes={{
                root: classes.cssCheck,
              }}
            />
            <div className={classes.butTerms}>
              ¡Registra un producto y aumenta tus oportunidades de ganar!
            </div>
          </div>
        );

      case "lugar":
        if (this.state.hasProduct) {
          return (
            <TextField
              key="lugar"
              error={this.state.errorIn_lugar}
              id="outlined-lugar-input"
              label={"Lugar de compra"}
              //label={this.state.lugar!=''?null:"Lugar de compra"}
              className={classes.textField}
              onKeyPress={this.hitEnter}
              InputLabelProps={{
                classes: {
                  root: classes.cssLabel,
                  focused: classes.cssFocused,
                },
              }}
              InputProps={{
                disableUnderline: true,
                classes: {
                  root: classes.cssOutlinedInput,
                },
              }}
              margin="normal"
              variant="filled"
              value={this.state.lugar}
              onChange={this.handleChange("lugar")}
              inputRef={(input) => (this["ref_" + typeField] = input)}
              onClick={() => {
                this["ref_" + typeField].focus();
              }}
              //multiline={true}
              rowsMax={1}
            />
          );
        }
        return "";

      case "referencia":
        //console.log('GetReg<'+this.state.referencia+'>');
        if (this.state.hasProduct) {
          return (
            <div
              style={{
                width: "40%",
                position: "relative",
                marginRight: "45px",
              }}
              key="contRegistro"
            >
              <TextField
                style={{ width: "100%" }}
                id="standard-select-currency-native-referencia"
                className={classes.textField}
                select
                label="Producto"
                value={this.state.referencia}
                onChange={this.handleChange("referencia")}
                SelectProps={{
                  MenuProps: {
                    className: classes.menu,
                  },
                }}
                InputLabelProps={{
                  classes: {
                    root:
                      this.state.referencia === ""
                        ? classes.cssLabel
                        : classes.cssLabelDense,
                    focused: classes.cssFocused,
                  },
                }}
                InputProps={{
                  disableUnderline: true,
                  classes: {
                    root: classes.cssOutlinedInput,
                  },
                }}
                margin="normal"
                variant="filled"
                //multiline={true}
                rowsMax={1}
              >
                {this.fillReferences()}
              </TextField>
              {/* <div id="question">
                  <div id="question_info"></div>
                  </div> */}
            </div>
          );
        }
        return "";

      case "serial":
        return (
          <TextField
            key="serial"
            error={this.state.errorIn_serial}
            id="outlined-serial-input"
            label={"Serial"}
            //label={this.state.serial!=''?null:"Serial"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
            value={this.state.serial}
            onChange={this.handleChange("serial")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      case "telefono":
        return (
          <TextField
            key="telefono"
            error={this.state.errorIn_telefono}
            id="outlined-phone-input"
            label={"Teléfono"}
            //label={this.state.telefono!=''?null:"Teléfono"}
            className={classes.textField}
            onKeyPress={this.hitEnter}
            InputLabelProps={{
              classes: {
                root: classes.cssLabel,
                focused: classes.cssFocused,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: {
                root: classes.cssOutlinedInput,
              },
            }}
            margin="normal"
            variant="filled"
            value={this.state.telefono}
            onChange={this.handleChange("telefono")}
            inputRef={(input) => (this["ref_" + typeField] = input)}
            onClick={() => {
              this["ref_" + typeField].focus();
            }}
            //multiline={true}
            rowsMax={1}
          />
        );

      default:
        break;
    }
  }

  render() {
    const { classes } = this.props;

    let screenHe = window.screen.availHeight * 0.55;

    let arrFields = [
      "nombre",
      "cedula",
      "apellido",
      "correo",
      "password",
      "password_confirm",
      "telefono",
      "ciudadCompra",
      "hasProduct",
      "lugar",
      "referencia",
    ];

    return (
      <Fragment>
        <Screen
          back={this.closePopUp}
          logo={"Registro"}
          showLoader={this.state.showLoader}
          screenCss={{
            height: "auto",
          }}
        >
          <div id="imageCenter" style={{ marginTop: "10px" }} />
          <div className={classes.mainDiv}>
            <div className="text-white text-center">
              <h1>Regístrate.</h1>
              <p>
                Juega y gana un combo futbolero Indurama. Si ya tienes cuenta,
                ¡participa!
              </p>
            </div>
            <div
              className={[classes.botConfirmar, classes.botSubir].join(" ")}
              style={{ marginBottom: "15px" }}
              onClick={() => {
                this.props.expandLogin(true);
              }}
            >
              <span>Iniciar sesión</span>
            </div>
            <form id="formRegistro" className={classes.form}>
              {arrFields.map((currentField) => {
                return this.getField(currentField, classes);
              })}

              {useBirthDay && (
                <div id="dataBirth" className={classes.dataBirth}>
                  <div id="birthLabel" className={classes.birthLabel}>
                    Fecha de nacimiento
                  </div>
                  <FormControl variant="filled" className={classes.dayField}>
                    <InputLabel
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-day-simple"
                      classes={{
                        root: classes.cssOutlinedInput,
                      }}
                    >
                      Día
                    </InputLabel>
                    <Select
                      value={this.state.dia}
                      onChange={this.handleChangeSelect}
                      input={
                        <Input labelWidth={25} name="dia" id="dia-simple" />
                      }
                      classes={{
                        root: classes.cssOutlinedInput,
                        icon: classes.cssOutlinedInput,
                      }}
                    >
                      {this.fillDias()}
                    </Select>
                  </FormControl>

                  <FormControl variant="filled" className={classes.monthField}>
                    <InputLabel
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-month-simple"
                      classes={{
                        root: classes.cssOutlinedInput,
                      }}
                    >
                      Mes
                    </InputLabel>
                    <Select
                      value={this.state.mes}
                      onChange={this.handleChangeSelectMes}
                      input={
                        <Input labelWidth={32} name="mes" id="mes-simple" />
                      }
                      classes={{
                        root: classes.cssOutlinedInput,
                        icon: classes.cssOutlinedInput,
                      }}
                    >
                      {this.fillMeses()}
                    </Select>
                  </FormControl>

                  <FormControl variant="filled" className={classes.yearField}>
                    <InputLabel
                      ref={(ref) => {
                        this.InputLabelRef = ref;
                      }}
                      htmlFor="outlined-year-simple"
                      classes={{
                        root: classes.cssOutlinedInput,
                      }}
                    >
                      Año
                    </InputLabel>
                    <Select
                      value={this.state.anio}
                      onChange={this.handleChangeSelectMes}
                      input={
                        <Input labelWidth={30} name="anio" id="anio-simple" />
                      }
                      classes={{
                        root: classes.cssOutlinedInput,
                        icon: classes.cssOutlinedInput,
                      }}
                    >
                      {this.fillAnios()}
                    </Select>
                  </FormControl>
                </div>
              )}
              {/*<MuiThemeProvider theme={materialTheme}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={esLocale}>
                <DatePicker
                  className={classes.dateField}
                  variant="filled"
                  label="Fecha de nacimiento"
                  format="d MMM yyyy"
                  value={this.state.birthdate}
                  onChange={this.handleDateChange}                  
                  animateYearScrolling
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,   
                      focused: classes.cssFocused,                   
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.cssOutlinedInput,                      
                    },
                  }}
                />
              </MuiPickersUtilsProvider>
            </MuiThemeProvider>*/}

              {useAddress && (
                <TextField
                  error={this.state.errorIn_direccion}
                  id="outlined-address-input"
                  label="Dirección"
                  className={classes.textField}
                  onKeyPress={this.hitEnter}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,
                      focused: classes.cssFocused,
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.cssOutlinedInput,
                    },
                  }}
                  margin="normal"
                  variant="filled"
                  value={this.state.direccion}
                  onChange={this.handleChange("direccion")}
                  //multiline={true}
                  rowsMax={1}
                />
              )}

              {useDepto && (
                <FormControl
                  variant="filled"
                  className={classes.dateField}
                  error={this.state.errorIn_departamento}
                >
                  <InputLabel
                    ref={(ref) => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-departamento-simple"
                    classes={{
                      root: classes.cssOutlinedInput,
                    }}
                  >
                    Departamento
                  </InputLabel>
                  <Select
                    value={this.state.departamento}
                    onChange={this.handleChangeSelect}
                    input={
                      <Input
                        labelWidth={102}
                        name="departamento"
                        id="departamento-simple"
                      />
                    }
                    classes={{
                      root: classes.cssOutlinedInput,
                      icon: classes.cssOutlinedInput,
                    }}
                  >
                    {this.fillDeptos()}
                  </Select>
                </FormControl>
              )}

              {this.state.showCitySelect && (
                <FormControl variant="filled" className={classes.dateField}>
                  <InputLabel
                    ref={(ref) => {
                      this.InputLabelRef = ref;
                    }}
                    htmlFor="outlined-ciudad-simple"
                    classes={{
                      root: classes.cssOutlinedInput,
                    }}
                  >
                    Ciudad
                  </InputLabel>
                  <Select
                    error={this.state.errorIn_ciudad}
                    value={this.state.ciudad}
                    onChange={this.handleChangeSelect}
                    input={
                      <Input labelWidth={51} name="ciudad" id="ciudad-simple" />
                    }
                    classes={{
                      root: classes.cssOutlinedInput,
                      icon: classes.cssOutlinedInput,
                    }}
                  >
                    {this.fillCiudades()}
                  </Select>
                </FormControl>
              )}

              {/*<div id="divFollowInsta" className={classes.divFollowInsta} href="#" onClick={() => {window.open("https://www.instagram.com/chococono_/");}}>
              <div id="labelInsta" className={classes.labelInsta}>Para participar y ganar debes seguir nuestra cuenta en Instagram</div>
              <div id="insta_logo"></div>
            </div>*/}

              {this.state.hasProduct && (
                <ImageUploader
                  withIcon={true}
                  buttonText="Choose images"
                  onChange={this.subirFactura}
                  imgExtension={[".jpg", ".jpeg", ".png", ".pdf"]}
                  maxFileSize={5242880}
                  withPreview={true}
                  singleImage={true}
                  fileName={this.state.fileName}
                  cssButton={{ marginRight: "45px" }}
                />
              )}

              <div id="labelAcepto">
                <Checkbox
                  checked={this.state.termsChecked}
                  onChange={this.handleChangeCheck()}
                  onKeyPress={this.hitEnter}
                  value="checkedTerms"
                  classes={{
                    root: classes.cssCheck,
                  }}
                />
                <div
                  className={classes.butTerms}
                  onClick={() => {
                    this.showTerms();
                  }}
                >
                  Acepto términos y condiciones y la política de tratamiento de
                  datos
                </div>
              </div>
            </form>

            <div id="buttonRegContainer">
              {/*<Button variant="contained" color="primary" className={classes.buttonReg} onClick={() => {this.registerUser()}}>
              Registrarse
            </Button>*/}
              <div
                className={[classes.botConfirmar, classes.botSubir].join(" ")}
                onClick={() => {
                  this.registerUser();
                }}
              >
                <span>Confirmar registro</span>
              </div>
            </div>
          </div>
        </Screen>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(Registro);
