import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({  
    divScore: {
      fontSize: "60px",
      zIndex: "100",
      position: "absolute",
      top: "80px",
      right: "32px",  
      color: "white",
      display: "flex",
      flexDirection: "column",
      alignItems: "center", 
      textShadow: "2px 3px 6px #000000",    
    },

    tiempo: {
      fontFamily: "Titillium Web",
      fontSize: "36px",
      lineHeight: "20px",
    },

    score: {
      fontFamily: "Titillium Web",
      fontSize: "70px",
      lineHeight: "58px",
    }
});

class GameCalvin extends Component {
 
  state = {
    tm: '',
  };
  
  constructor() {
    super();
    window.windowManager.calvin = this.newCalvin;
  }

  newCalvin = (tm) => {
    this.setState({tm});
  }

  render() {
    
    const { classes } = this.props;


    return (
      <Fragment>
        {this.state.tm!=='' && <div className={classes.divScore}>
          <div className={classes.tiempo}>TIEMPO</div>
          <div className={classes.score}>{this.state.tm}</div>
          <div id="decoScore" />
        </div>}
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(GameCalvin));