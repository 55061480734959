import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import Screen from './Screen'


const styles = theme => ({  

    mainDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }, 

});

class Premios extends Component {
  
  state = {    
    showLoader: false,
  }

  render() {
    
    const { classes } = this.props;

    return (
      <Fragment>
        <Screen back={this.closePopUp} showLoader={this.state.showLoader} contentCss={{
          padding: "0 2%",
          width: "80%",
          margin: "10vh auto",
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}>
          <div className={classes.mainDiv}>                  
            <div id="imageCenterAwards"></div>
            <img src="assets/logo_premios.png" alt="" style={{display:"block", margin:"0 auto", width: "40%"}}/>
            {/* <div id = "textAwards"></div>      */}
            <div id = "textAward" >
              <h2 className='my-0'>¡GANA MÁS Y DISFRUTA A LO GRANDE!</h2>
              <p className='mt-o'>
              Participa del 3 de junio al 14 de julio de 2024 hasta las 23:59 y gana un increíble combo futbolero Indurama. <br />
* Una TV de 50”<br />
* Un parlante<br />
* Un minibar<br />
Compra, juega y gana.  <br />
¡Participa ya!

              </p>
             
            </div>
        </div>
        </Screen>
        <div id="botAtras" onClick={() => {
            this.props.backFromScreen()
          }}></div>
      </Fragment>
    );
  }

}

export default (withStyles(styles,{ withTheme: true })(Premios));