import React, { Component,Fragment } from 'react';

import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PopUp from './PopUp'

const styles = theme => ({  
    button: {
      margin: theme.spacing.unit,
      backgroundColor: "#109cea",
      borderRadius: 30,      
    },

    help: {
      height: "50vh",
      color: "white",
      paddingRight: "15px",
      textAlign: "center",
      background: "url(./assets/background/help.png) no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "50% 50%",
    }
});

class Help extends Component {
 
  state = {    
    opened: true,
  };

  closePopUp = () => {
    this.props.back();
  }

  closeHelp = () => {
    this.setState({opened:false})    
  }

  render() {
    
    const { classes } = this.props;

    return (
      <Fragment>
        <PopUp back={this.closePopUp} logo={"Help"} opened={this.state.opened}>          
            <div id="imgHelp" className={classes.help}>              
            </div>

            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={this.closeHelp}>
                Regresar
              </Button>
            </div>          
        </PopUp>
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(Help));