import React, { Component, Fragment } from "react";
import { withStyles } from "@material-ui/core/styles";
import classNames from 'classnames';


const styles = theme => ({
  rankingPaginator: {
    height: "50px",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
    marginTop: "20px",
  },
  
  wrapperFlecha: {
    width: "30px",
    height: "30px",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
  },

  flechaDerecha: {
    width: "7px",
    height: "10px",
    borderTop: "solid 3px white",
    borderRight: "solid 3px white",    
    transform: "rotate(45deg)",
    marginLeft: "9px",  
  },

  flechaIzquierda: {
    width: "7px",
    height: "10px",
    borderTop: "solid 3px white",
    borderRight: "solid 3px white",    
    transform: "rotate(-135deg)",
    marginLeft: "9px",  
  },  
  
  number: {
    width: 30,
    height: 30,
    color: "white",
    fontFamily: 'Titillium Web', 
    fontSize: "22px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",   
    cursor: "pointer",
    margin: "6px",
    transform: "skew(-8deg,0deg)",
  },

  selectedNumber: {
    backgroundColor: 'white',
    color: "#00318c",
    fontSize: "26px",    
  },

});

class Paginator extends Component {
  
  state = {
    currentPage: 0,    
    firstItem: 0,
  };

  /*componentDidUpdate() {
    console.log('Datos',this.props.totalItems,this.props.itemsPerPage);

    
  }*/

  selectPage = (num,firstItem) => {
    console.log('selectPage',num,firstItem);
    let objNew = {currentPage:num};
    
    if(firstItem!==undefined) {
      objNew.firstItem = firstItem;
    }

    this.setState(objNew);
    this.props.onPageChange(num);
  }

  nextPage = () => {
    if(this.state.currentPage<this.totalPages-1) {
      let firstItem = this.state.firstItem;

      if((this.state.currentPage+1)-firstItem>5) {
        firstItem++;
      }
     
      this.selectPage(this.state.currentPage+1,firstItem);      
    }
  }

  previousPage = () => {
    if(this.state.currentPage>0) {
      let firstItem = this.state.firstItem;
      if((this.state.currentPage-1)<firstItem) {
        firstItem--;
      }

      this.selectPage(this.state.currentPage-1,firstItem);
    }
  }
  
  render() {
    const { classes,totalItems,itemsPerPage } = this.props;

    var pages = [];
    var lastPage = this.state.firstItem+6;
    
    this.totalPages = Math.ceil(this.props.totalItems/this.props.itemsPerPage);
    
    if(lastPage>this.totalPages) lastPage = this.totalPages;
    
    console.log('--Con props en ','totalItems',totalItems,'itemsPerPage',itemsPerPage,'lastPage',lastPage,'firstItem',this.state.firstItem,'totalPages',this.totalPages);

    for (var i = this.state.firstItem; i < lastPage; i++) {      
      pages.push(i);
    }
    
    return (
      <div id="rankingPaginator" className={classes.rankingPaginator}>
        <div className={classes.wrapperFlecha} onClick={() => {this.previousPage()}}>
          <div className={classes.flechaIzquierda}></div>
        </div>
        <div style={{display: 'flex'}}>
          {pages.map((data) => {            
            return <div key={data} className={classNames(classes.number, this.state.currentPage==data && classes.selectedNumber)}
              onClick={() => {this.selectPage(data)}}>{data+1}</div>
          })}
        </div>
        <div className={classes.wrapperFlecha} onClick={() => {this.nextPage()}}>
          <div className={classes.flechaDerecha}></div>
        </div>
      </div>        
    );
  }
}

export default withStyles(styles, { withTheme: true })(Paginator);
