import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import GameCalvin from './GameCalvin';

import "./Ingresar.css";

const styles = theme => ({
  fulldiv: {
    width: "100%",
    height: "100%",
    backgroundColor: '#000E',
    position: "fixed",
    top: "0",
    display: "flex",
    alignItems: "center",
    zIndex: "10",
  },

});

class Game1 extends Component {
  state = {    
  };

  closePopUp = () => {
    this.props.back();
  };

  closePopUpWithAnim = () => {
    this.setState({ opened: false });
  };

  componentDidMount() {    
    
    window.use_sound = false;
    /*area_goal: [{id: 0, cuposo: 100}, {id: 1, cuposo: 100}, {id: 2, cuposo: 100},
                        {id: 3, cuposo: 100}, {id: 4, cuposo: 100}, {id: 5, cuposo: 100},
                        {id: 6, cuposo: 100}, {id: 7, cuposo: 100}, {id: 8, cuposo: 100},
                        {id: 9, cuposo: 100}, {id: 10, cuposo: 100}, {id: 11, cuposo: 100},
                        {id: 12, cuposo: 100}, {id: 13, cuposo: 100}, {id: 14, cuposo: 100}], //cuposo AREA GOALS START TO LEFT UP TO RIGHT DOWN*/
    new window.CMain({
                    area_goal: [{id: 0, cuposo: 100}, {id: 1, cuposo: 80}, {id: 2, cuposo: 60},
                        {id: 3, cuposo: 80}, {id: 4, cuposo: 100}, {id: 5, cuposo: 75},
                        {id: 6, cuposo: 60}, {id: 7, cuposo: 50}, {id: 8, cuposo: 60},
                        {id: 9, cuposo: 75}, {id: 10, cuposo: 80}, {id: 11, cuposo: 65},
                        {id: 12, cuposo: 70}, {id: 13, cuposo: 65}, {id: 14, cuposo: 80}], //cuposo AREA GOALS START TO LEFT UP TO RIGHT DOWN*/  
                    //0  1  2  3  4
                    //5  6  7  8  9
                    //10 11 12 13 14
                    num_of_penalty: 4, //MAX NUMBER OF PENALTY FOR END GAME
                    multiplier_step: "cad2853d5f6f67de406cc976c340483c47248370610b020499810c5ab112f402U9fgqsT3Jr/gdOys5+CA0w==", //INCREASE MULTIPLIER EVERY GOAL
                    fullscreen: true, //SET THIS TO FALSE IF YOU DON'T WANT TO SHOW FULLSCREEN BUTTON
                    check_orientation:true,     //SET TO FALSE IF YOU DON'T WANT TO SHOW ORIENTATION ALERT ON MOBILE DEVICES
                    num_levels_for_ads: 200//NUMBER OF TURNS PLAYED BEFORE AD SHOWING //
                            //////// THIS FEATURE  IS ACTIVATED ONLY WITH CTL ARCADE PLUGIN./////////////////////////// 
                            /////////////////// YOU CAN GET IT AT: ///////////////////////////////////////////////////////// 
                            // http://codecanyon.net/item/ctl-arcade-wordpress-plugin/13856421 ///////////
                });

    if (window.isIOS()) {
        setTimeout(function () {
            window.sizeHandler();
        }, 200);
    } else {
        window.sizeHandler();
    }

    this.forceUpdate();
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.fulldiv} id="game1div">        
        <canvas id="canvas" className='ani_hack' width="1360" height="640" style={{height: "100% !important",}}> </canvas>                      
      </div>       
    );    
  }
}

export default withStyles(styles, { withTheme: true })(Game1);
