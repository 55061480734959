import React, { Component,Fragment } from 'react';
import './PopUp.css';

import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

import Backdrop from '@material-ui/core/Backdrop';


const styles = theme => ({  
    modalWn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',         
    },

    circular: {
        outline: "0px solid transparent",
        position: "absolute",
        left: "calc( 50% - 25px )",
        top: "calc( 50% )",
    },
});

export class BackDropIOSWorkaround extends React.PureComponent<BackdropProps> {
    onTouchMove(event: React.TouchEvent<HTMLDivElement>): void {
      console.log('Prevent')
      event.preventDefault();
    }

    handleClose() {
      console.log('Aca')
    }

    render() {
        return (
            <Backdrop {...this.props} onTouchMove={this.onTouchMove} onClose={this.handleClose}/>
        );
    }
}

class PopUp extends Component {
 
  state = {
    opened: true,
    name: '',
    mail: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({opened:false});    
  };

  closePopUp = () => {
    this.props.back();
  }

  render() {
    
    const { classes,opened,showLoader } = this.props;


    let realOpened = opened!==undefined?opened&&this.state.opened:this.state.opened
    
          //onClose={this.handleClose}
    return (
      <Fragment>
        <Modal
          open={true}      
          BackdropComponent={BackDropIOSWorkaround}    
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          classes={{ root: classes.modalWn }}    
          disableAutoFocus={true}      
        >
          <Fragment>
          {this.props.logo!=="none" && <Fragment>
            <Grow in={realOpened} onExited={() => {this.closePopUp()}}>
              <div id="popContainer">
                {/*<div id="buttonClose" onClick={this.handleClose} />*/}
                <div id="botClose" onClick={this.handleClose}>CERRAR</div>
                <div id="brownBackCont">
                  <div id="brownBackContInt">
                    <div id="brownBack"></div>
                  </div>
                  <div id={"logoPopUp-"+this.props.logo} className={"logoClass"}></div>              
                </div>
                <div id="brownBack2">
                  <div id="ornaments">
                    <div>
                      <div id="ornament00"></div>
                      <div id="ornament01"></div>
                      <div id="ornament02"></div>
                    </div>                
                  </div>

                  <div id={"content"} style={this.props.contentCss}>                  
                    {this.props.children}
                  </div>
                </div>
              </div>
            </Grow>            
          </Fragment>}
          {this.props.logo==="none" && <Fragment>
            <Grow in={realOpened} onExited={() => {this.closePopUp()}}>
              <div id="popContainer">
                <div id="buttonCloseNoLogo" onClick={this.handleClose} />              
                <div id="brownBack2NoLogo">                
                  <div id="contentNoLogo">                  
                    {this.props.children}
                  </div>
                </div>
              </div>
            </Grow>
          </Fragment>}
          {showLoader && <div id="loaderDiv">
            <CircularProgress size={50} className={classes.circular} />
          </div>}
          </Fragment>
        </Modal>
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(PopUp));