import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Screen from './Screen'

import { sesionManager } from './SesionManager'

import TextField from '@material-ui/core/TextField';

const sha1 = require('js-sha1');
const request = require('superagent');


const styles = theme => ({  

    mainDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },

    botConfirmar: {
      height: "27px",
      minWidth: "0px !important",
      justifyContent: "center",
      maxWidth: "225px",
    },    

    textConfirm: {
      fontFamily: "Titillium Web",
      color: "white",      
      maxWidth: "420px",
      textAlign: "center",
    },

    botSubir: {
      width: "60%",
      display: "flex",
      position: "relative",
      minWidth: "270px",
      margin: '8px auto',
      backgroundColor: "#00318c",
      fontFamily: "Titillium Web",
      color: "white",      
      alignItems: "center",
      cursor: "pointer",
    },


    cssLabel: {
      fontFamily: 'Titillium Web',  
      transform: "translate(8px, 18px) scale(1)",  
    },

    cssOutlinedInput: {
      fontFamily: 'Titillium Web',
      fontSize: "0.8em",    
    },

});

class ConfirmMail extends Component {
 
  state = {    
    correo: '',
  };

  hitEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.recuperarPass();
      ev.preventDefault();
    }
  }
  
  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  componentWillMount() {
    if(this.props.popUpParams.email) {
      this.setState({correo:this.props.popUpParams.email})
    }
  }

  resendConfirmationMail = () => {
    this.setState({ showLoader: true });
    
    console.log('Hola')
    request
      .post(process.env.REACT_APP_SERVER_URL + "resend_confirmation.php")
      .query({ correo : this.state.correo })
      .end((err, res) => {
        this.setState({ showLoader: false });
        console.log('Llega info',res);
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          console.log(jsonRes, jsonRes["success"], jsonRes.success);
          if (jsonRes.success === "false") {
            if (jsonRes.regError === "true") {
              console.log('Manda un mensaje');
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError(
                "Ha ocurrido un error al intentar usar el código, si el error persiste contactate al correo " +
                  process.env.REACT_APP_SUPORT_MAIL +
                  "\n\n[error: " +
                  jsonRes["msg"] +
                  "]"
              );
            }
          }
          else {
            this.props.showAppError("Hemos enviado de nuevo un correo electrónico para que puedas confirmar tu cuenta.");
            this.props.setCurrentScreen("register");
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  };

  render() {
    
    const { classes,hash } = this.props;

    return (
      <Fragment>
        <Screen back={this.closePopUp} showLoader={this.state.showLoader} contentCss={{
          padding: "0 2%",
          width: "80%",
          margin: "10vh auto",
          overflowY: 'scroll',
        }}>
          <div className={classes.mainDiv}>
            <div id="botAtras" onClick={() => {
              this.props.setCurrentScreen("register")
            }}></div>
            <div id="imageCenter"></div>
              <div id="imageCenterConfirm"></div>
              <div className = {classes.textConfirm}> 
                La cuenta con el correo electronico <strong>{this.props.popUpParams.email}</strong> no se encuentra confirmada, revisa tu correo y confirmala para poder participar
              </div>
            <div className={[classes.botConfirmar,classes.botSubir].join(' ')} style={{marginBottom: "15px"}} onClick={() => {this.resendConfirmationMail();}}>
              <span>Reenviar correo de confirmación</span>
            </div> 
      
          </div>
        </Screen>
      </Fragment>
    );
  }

}

export default (withStyles(styles,{ withTheme: true })(ConfirmMail));