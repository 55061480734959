//Tulio = Score
//Calvin = Time

import React, { Component,Fragment } from 'react';
import PropTypes from 'prop-types';
import './App.css';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core';

import purple from '@material-ui/core/colors/purple';


import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import Registro from './Registro'
import RestorePassFirstStep from './RestorePassFirstStep'
import Home from './Home'
import Ingresar from './Ingresar'
import RestorePass from './RestorePass'
import Help from './Help'
import UseCode from './UseCode'
import ConfirmMail from './ConfirmMail'
import Ranking from './Ranking'
import Premios from './Premios'
import Ganadores from './Ganadores'
import AppError from './AppError'
import Game1 from './Game1'
import Game2 from './Game2'
import LoginFlag from './LoginFlag'
//import CameraReact from './CameraReact'
//import Camera from './Camera'

import { loadScriptsParallel, loadScriptsSecuential, loadScriptsParallelWithDependencies} from './utils/loadScripts';

import { sesionManager } from './SesionManager'
import windowManager from './WindowManager'

const hasMecanica = true;

const request = require("superagent");


const drawerWidth = 240;
const textFieldsForm = createMuiTheme({
  palette: {
    primary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
      contrastText: '#FFF'
    },
    secondary: {
      light: '#FFF',
      main: '#FFF',
      dark: '#FFF',
      contrastText: '#FFF'
    },
    
  },
  typography: {
    useNextVariants: true,
  },  
  overrides: {
    MuiInputBase: {
      input: {
        color: '#5e5f5f',
      }
    },  
    MuiFormHelperText: {
      root: {
        position: "absolute",
        top: "0",
        right: "0",        
        fontSize: "0.7em",
      },
      contained: {
        margin: "3px 2px",
      }
    },
    MuiInputLabel: {
      filled:{
        transform: 'translate(8px, 8px) scale(1)',
        '&$shrink': {
          transform: 'translate(8px, 4px) scale(0.75)',          
        }
      }
    },
    MuiFilledInput: {
      root: {
        backgroundColor: 'white',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,        
        '&:hover': {
          backgroundColor: 'white !important',
        },
        '&$focused': {
          backgroundColor: 'white',
        },
      },
      multiline: {
        padding: '',
      },
      input: {
        padding: "2px 0px 2px 8px",
      }
    },
    MuiSelect: {
      root: {
        backgroundColor: 'white',        
      },
      select: {
        paddingLeft: "8px",
      }      
    },
    MuiFormLabel: {
      root: {
        '&$focused': {
          color: '#00318c',
          //visibility: 'hidden',
        },
      }
    }  
  },
});


const styles = theme => ({  
    button: {
      margin: theme.spacing.unit,
      backgroundColor: "#b718e6",
      borderRadius: 30,
      '&:hover': {
        backgroundColor: "#691881",
      }
    },

    buttonReg: {
      margin: theme.spacing.unit,
      backgroundColor: "#109cea",
      borderRadius: 30,
    },

    divPopUp: {
      background: 'url(assets/popup.jpg) no-repeat;',
    },

    menuButton: {
      marginLeft: 12,
      marginRight: 20,
      color: "white",
    },
    
    hide: {
        display: 'none',
    },

    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },

    drawerPaper: {
        width: drawerWidth,
        backgroundColor: "#00318c",
    },

    header : {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
    },

    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: '0 8px',
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },

    circular: {
        outline: "0px solid transparent",
        position: "absolute",
        left: "calc( 50% - 25px )",
        top: "calc( 50% )",
    },

    divMecanicaContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      height: "100vh",
      width: "100vw",
      position: "absolute",
      zIndex: "3",
      backgroundColor: "rgba(0, 0, 0, 0.5)",      
    },

    divMecanicaClose: {
      position: 'absolute',
      top: 10,
      right: 10,
    },
});

const isMobile = require('ismobilejs');

var game;
let showPreloader = false;
let userLogged = null;


class App extends Component {

  state = {
    userLogged: null,
    currentPopUp: "none", // ["none", "ranking","register","login","help","use_code","restore_pass","no_confirmed"]
    currentScreen: "register", // ["register","restore_pass_first_step","ranking","premios","home"]
    popUpParams: {},
    menuOpened: false,
    showAppError: "",
    callbackError: null,
    showLoader: false,
    hash_restore: '', //Hash para la ventana de restaurar password
    CalvinRemainedToShow: "--:--:--",
    msgDebug:"",
    showGame1:false,
    showGame2:false,
    loginExpanded:false,
    showMecanica: false,
    showGanadores: false,
    hasGanadores: false,
    showPopUp: false,
  }

  constructor(props) {
    super(props); 
    window.sesionManager = sesionManager;
    sesionManager.regMainScreen(this);    
    windowManager.regMainScreen(this);  
  }

  closeGame = () => {
      this.setState({showGame1:false,showGame2:false});
  }

  checkGanadores = () => {

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = 'assets/ganadores.jpg';
    
    //img.src = 'https://www.masqueuntv.com/ganadores/ganadores.jpg';
    //img.src = 'ganadores/ganadores.jpg';
    
    img.onload = () => {
      console.log('0000 Carga');
      this.setState({hasGanadores:true});
    }

    img.onerror = function(e) {
      console.log('0000 could not download image');
    };
  }

  checkPopUp = () => {

    const img = new Image();
    img.crossOrigin = "anonymous";
    img.src = 'assets/popup3.jpg';
    
    //img.src = 'https://www.masqueuntv.com/ganadores/ganadores.jpg';
    //img.src = 'ganadores/ganadores.jpg';
    
    img.onload = () => {
      console.log('0000 Carga');
      this.setState({showPopUp:true});
    }

    img.onerror = function(e) {
      console.log('0000 could not download image');
    };
  }

  componentDidMount() {
      
      this.checkGanadores();
      this.checkPopUp();

      document.documentElement.style.display = 'block';

      var objUrl = this.getJsonFromUrl(window.location.search);

      //si viene un hash es para restarurar contraseña
      if(objUrl.hash){
          window.history.replaceState({}, document.title, "/");
          this.setState({currentScreen:"restore_pass_first_step",hash_restore:objUrl.hash})        
      }

      //si viene como parametro token_confirm es porque es confirmar la cuenta (validar mail)
      if(objUrl.token_confirm){
          window.history.replaceState({}, document.title, "/");
          this.setState({showLoader:true});
          sesionManager.confirmUser(objUrl.token_confirm);
      }    

      //this.calcScale();
      this.tryRestoreSesion(() => {this.createGame()});    

      let isIE = false || !!document.documentMode;

      if(isIE) {
        this.showAppError("Este sitio se visualiza mejor con Chrome.");
      }
  }  

  updateRemainingCalvin(newCalvin) {
    this.setState({CalvinRemainedToShow:newCalvin})
  }  

  tryRestoreSesion(initGame) {
    if(sesionManager.getCookie("idUser")!==undefined) {
        ////console.log('PoneLoader')
        this.setState({showLoader:true})
        
        //Primer parametro, cuando la sesion es valida //callbackGoodSesion
        //Segundo parametro, cuando no lo es //callbackBadSesion
        sesionManager.tryRestoreSesion(() => {
                userLogged = sesionManager.getCookie("nameUser");                
                this.setState({userLogged:userLogged,showLoader:false,currentScreen:"home"})
                //this.setState({userLogged:userLogged,showLoader:false,currentScreen:"ranking"})
                initGame();
            },() => { 
                this.setState({showLoader:false})
                initGame() }
        );        
    }
    else {
        initGame();
    }
  }

  setUserName = (newName) => {
    this.setState({userLogged:newName,currentScreen:'home'})
    userLogged = newName;
    this.createGame();
  }

  setUserLastName = (newLastName) => {
    this.setState({userLoggedLastname:newLastName})    
  }

  updateGame = () => {
    this.createGame();
  }

  forceExternalUpdate() {
    this.forceUpdate();
  }

  openPopUp(str) {
    this.setState({currentPopUp:str})
  }

  closePopUp() {
    this.openPopUp("none")
  }  

  closeSession() {
    userLogged = null;
    sesionManager.closeSession();
    this.setState({userLogged:null,showGame1:false,showGame2:false,currentScreen:"register"});
    this.createGame();    
  }

  handleDrawerOpen = () => {
    this.setState({ menuOpened: true });
  };

  handleDrawerClose = () => {
    this.setState({ menuOpened: false });
  };

  menuClicked = (text) => {    
    console.log('Entra click ',text);
    switch(text) {
        /*case 'Ingresar': this.openPopUp("login"); break;
        case 'Registro': this.openPopUp("register"); break;*/
        case 'Mecánica': this.setState({showMecanica:true});break;
        case 'Cerrar sesión': this.closeSession(); break;
        case 'Premios': this.setCurrentScreen("premios"); break;
        case 'Ganadores': this.setCurrentScreen("ganadores"); break;
        case 'Ranking': this.setCurrentScreen("ranking"); break;
        /*case 'Cómo Jugar': this.openPopUp("help"); break;*/
        case 'Términos y condiciones': this.showTerms(); break;
        default: break;
    }    

    this.handleDrawerClose();
  };

  showAppError = (str,callback) => {
    //console.log('Guarda callback',callback)
    this.setState({showAppError:str,callbackError:callback,showLoader:false});
  }

  showNoConfirmed = (email) => {
    this.setState({currentScreen:"ConfirmMail", loginExpanded: false,popUpParams:{email}});
  }

  closeAppError = () => {
    this.setState({showAppError:""});  
  }

  openGame = async (gameToOpen) => {

    await gameToOpen;
    if(windowManager.allAttempsInfo[gameToOpen-1].num_intentos_left<=0) {
      this.showAppError("No tienes mas rondas para jugar, para conseguirlas debes ingresar nuevas facturas");
      return;
    }

    this.setState({showLoader:true});
    if(gameToOpen===1) {
      sesionManager.getAttempsInfo(gameToOpen).then((attempsInfo) => {
        
        //console.log('llega',attempsInfo);

        windowManager.setAttempInfo(attempsInfo);

        //Si esto no esta es porque el juego 1 no se ha cargado
        if(window.CMain===undefined) {
          this.loadJsGame(true).then(() => {
            this.setState({showLoader:false,["showGame"+gameToOpen]:true});  
          });
        }
        else {
          this.setState({showLoader:false,["showGame"+gameToOpen]:true});  
        }
      })      
    } 
    else {
      sesionManager.getAttempsInfo(gameToOpen).then((attempsInfo) => {       
        //console.log('llega',attempsInfo); 
        windowManager.setAttempInfo(attempsInfo);
        this.setState({showLoader:false,["showGame"+gameToOpen]:true})
      })
    }
  }

  loadJsGame = (minified) => {
    //console.log('Cargar juego JS');
    return new Promise((resolve, reject) => {
      if(minified) {
        loadScriptsParallelWithDependencies([['js/game1/js/jquery-3.2.1.min.js?ver=1.2'],['js/game1/js/screenfull.js?ver=1.2'],['js/game1/js/howler.min.js?ver=1.2','js/game1/js/ctl_utils.js?ver=1.2','js/game1/js/cannon.js?ver=1.2'],['js/game1/allgame2.min.js?ver=1.2']])
        .then(() => {
          //console.log('Dice que listo');
          resolve();
        });

        /*loadScriptsSecuential(['js/screenfull.js?ver=1.2','js/howler.min.js?ver=1.2','js/ctl_utils.js?ver=1.2','js/cannon.js?ver=1.2','js/allgame1.min.js?ver=1.2'],() => {
              resolve();
        });*/        
      }
      else {
        loadScriptsParallelWithDependencies([['js/game1/js/jquery-3.2.1.min.js?ver=1.2'],["js/game1/js/createjs.min.js?ver=1.2","js/game1/js/screenfull.js?ver=1.2","js/game1/js/howler.min.js?ver=1.2","js/game1/js/sprite_lib.js?ver=1.2",
              "js/game1/js/settings.js?ver=1.2","js/game1/js/CLang.js?ver=1.2","js/game1/js/CPreloader.js?ver=1.2","js/game1/js/CMain.js?ver=1.2","js/game1/js/CTextButton.js?ver=1.2","js/game1/js/CToggle.js?ver=1.2","js/game1/js/CGfxButton.js?ver=1.2","js/game1/js/CMenu.js?ver=1.2",
              "js/game1/js/CGame.js?ver=1.2","js/game1/js/CInterface.js?ver=1.2","js/game1/js/cannon.js?ver=1.2","js/game1/js/CBall.js?ver=1.2","js/game1/js/CScenario.js?ver=1.2","js/game1/js/Three.js?ver=1.2","js/game1/js/Detector.js?ver=1.2",
              "js/game1/js/smoothie.js?ver=1.2","js/game1/js/Stats.js?ver=1.2","js/game1/js/dat.gui.js?ver=1.2","js/game1/js/CPopUp.js?ver=1.2","js/game1/js/CWinPanel.js?ver=1.2","js/game1/js/CAreYouSurePanel.js?ver=1.2","js/game1/js/CCreditsPanel.js?ver=1.2",
              "js/game1/js/CPause.js?ver=1.2","js/game1/js/CGoalKeeper.js?ver=1.2","js/game1/js/CStartBall.js?ver=1.2","js/game1/js/CVector2.js?ver=1.2","js/game1/js/CPlayer.js?ver=1.2","js/game1/js/CScoreBoard.js?ver=1.2","js/game1/js/CCalvin.js?ver=1.2","js/game1/js/CRollingScore.js?ver=1.2",
              "js/game1/js/CLaunchBoard.js?ver=1.2","js/game1/js/CHandSwipeAnim.js?ver=1.2","js/game1/js/CHelpText.js?ver=1.2","js/game1/js/CGoal.js?ver=1.2"],["js/game1/js/ctl_utils.js?ver=1.2","js/game1/js/cannon.demo.js?ver=1.2","js/game1/js/TrackballControls.js?ver=1.2"]])
        .then(() => {
          //console.log('Dice que listo');
          resolve();
        });
      }  
    })    
  }

  setRef(screen,ref) {
    this[screen + "Ref"] = ref;
  }

  refreshScores = (juego) => {
    //console.log('1 Refresque ',juego);
    sesionManager.askAndProcessRanking(juego).then(() => {      
      window.dispatchEvent(new CustomEvent("scoresRefreshed"));
      
      /*//console.log('2 Refresque ',juego);
      //Si esto se cumple entonces esta pintada la pantalla de home
      if(this.state.userLogged!==null) {
        //console.log('Le dice incluso que refresque');
        this.homeRef.refreshScores();
        this.homeRef.forceUpdate();
      }*/
    })
  }

  setCurrentScreen = (newScreen) => {
    
    let newState = {currentScreen:newScreen};

    if(newScreen==="restore_pass_first_step") {
      newState.hash_restore = "";
    }
    this.setState(newState);
  }
  
  expandLogin = (expanded) => {
    this.setState({loginExpanded:expanded});
  }

  backFromScreen = () => {
    switch(this.state.currentScreen) {
      case "ranking": if(this.state.userLogged===null) {
                        this.setState({currentScreen:"register"});
                      }
                      else {
                        this.setState({currentScreen:"home"});
                      }
                      break;
     case "premios": if(this.state.userLogged===null) {
                        this.setState({currentScreen:"register"});
                      }
                      else {
                        this.setState({currentScreen:"home"});
                      }
                      break;
      case "ganadores": if(this.state.userLogged===null) {
                        this.setState({currentScreen:"register"});
                      }
                      else {
                        this.setState({currentScreen:"home"});
                      }
                      break;
      default: break;
    }
  }

  render() {
  
    const { classes } = this.props;
    const { currentPopUp,currentScreen,termsOpened,showAppError,CalvinRemainedToShow } =  this.state;
    var paintMobile = isMobile.any;

    ////console.log(sesionManager.lastCalvinInMilis,'<>',CalvinRemainedToShow)

    ////console.log('Con ',this)

    let styleForCenter = {}
    let itemsMenu = []

    if(paintMobile) {
        styleForCenter = {
            height:"90%",
            position: "relative",
            top: "-10px",
        }

        /*else {
            itemsMenu.push('Ingresar', 'Registro')
        }*/
    }

    if(this.state.userLogged!==null) {
        itemsMenu.push('Cerrar sesión')
    }

    itemsMenu.push('Ranking', 'Premios');

    if(hasMecanica) itemsMenu.push('Mecánica')

    if(this.state.hasGanadores) itemsMenu.push('Ganadores')

    let paintClock = sesionManager.lastCalvinInMilis!==-1 && !sesionManager.allow_play_without_code; 

    //console.log('Que pinte con ',currentScreen);

    return (
      <div>
      <MuiThemeProvider theme={textFieldsForm}>
        {/*<CameraReact />*/}
        {!paintMobile && <div id="decoBackTop">
      
      
        </div>}

        {paintMobile && <div id="decoBackTopMobile" style={{minWidth:'none'}}>
          <div id="decoBackTopLeft"></div>          
        </div>}
        
        {showAppError!=="" && <AppError str={showAppError} onClose={() => {
            if(this.state.callbackError !== null && this.state.callbackError !== undefined) {
                this.state.callbackError();            
            }
            this.closeAppError()
        }}/>}

        {currentPopUp==="use_code" && <UseCode 
            back={() => {this.closePopUp()}}             
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
        />}

        {(this.state.userLogged===null && this.state.currentScreen==="register") && <Registro 
            back={() => {this.closePopUp()}} 
            showTerms={() => {this.showTerms()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            expandLogin={this.expandLogin}
        />}

        {this.state.showPopUp && <div className={classes.divMecanicaContainer}> 
            <div id="divPopUpFinal" className={classes.divPopUp} >
              <div id="divMecanicaClose" className={classes.divMecanicaClose} onClick={() => {                      
                      this.setState({showPopUp:false});
                    }}></div>
            </div>             
        </div>}

        {this.state.showMecanica && <div className={classes.divMecanicaContainer}>         
            <div id="divMecanica">
              <div id="divMecanicaClose" className={classes.divMecanicaClose} onClick={() => {                      
                      this.setState({showMecanica:false});
                    }}></div>
            </div>             
        </div>}

        {(this.state.userLogged===null && this.state.currentScreen==="ConfirmMail") && <ConfirmMail 
            back={() => {this.closePopUp()}} 
            showTerms={() => {this.showTerms()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            expandLogin={this.expandLogin}
            popUpParams={this.state.popUpParams}
            setCurrentScreen={this.setCurrentScreen}
        />}

        {(this.state.userLogged===null && this.state.currentScreen==="restore_pass_first_step") && <RestorePassFirstStep 
            hash={this.state.hash_restore}
            back={() => {this.closePopUp()}} 
            showTerms={() => {this.showTerms()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            setCurrentScreen={this.setCurrentScreen}
        />}


        {this.state.userLogged!==null && this.state.currentScreen==="home" && <Home 
            mainScreen={this}
            openGame={this.openGame}
            back={() => {this.closePopUp()}} 
            userLogged= {this.state.userLogged}
            userLoggedLastname= {this.state.userLoggedLastname}
            showTerms={() => {this.showTerms()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
        />}

        {this.state.currentScreen==="ranking" && <Ranking 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            backFromScreen={this.backFromScreen}
        />}

        {this.state.currentScreen==="premios" && <Premios 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            backFromScreen={this.backFromScreen}
        />}

        {this.state.currentScreen==="ganadores" && <Ganadores 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            backFromScreen={this.backFromScreen}
        />}

        {currentPopUp==="help" && <Help back={() => {this.closePopUp()}} />}
        
        {currentPopUp==="login" && <Ingresar 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            showNoConfirmed={this.showNoConfirmed}
        />}

        {currentPopUp==="restore_pass" && <RestorePass 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
            hash={this.state.hash_restore}
        />}

        {currentPopUp==="ranking" && <Ranking 
            back={() => {this.closePopUp()}} 
            showAppError={(str,callback) => {this.showAppError(str,callback)}}
        />}
        
        

        <div id="menu" className="menu" style={{display:paintMobile?'flex':''}}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={this.handleDrawerOpen}
              className={classNames(classes.menuButton, this.state.menuOpened && classes.hide)}
            >
              <MenuIcon fontSize="large" />
            </IconButton>
            {paintClock && <div id="timeRemainedMobile"><div id="timeRemainedDiv">{"Código valido por "+CalvinRemainedToShow}</div></div>}
            <Drawer
              className={classes.drawer}
              anchor="left"
              open={this.state.menuOpened}
              onClose={() => {//console.log('Llama')
                this.handleDrawerClose()}}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <div className={classes.drawerHeader}>
                {paintClock && <div id="userNameMobile"><div id="userNameDivMobile">{this.state.userLogged}</div></div>}
                <IconButton onClick={this.handleDrawerClose} style={{color:'white'}}>
                  <ChevronLeftIcon />
                </IconButton>
              </div>
              <Divider style={{backgroundColor:'white'}} />
              <List>
                {itemsMenu.map((text, index) => (
                  <ListItem button key={text} onClick={() => {this.menuClicked(text)}}>
                    <ListItemText primary={text} primaryTypographyProps={{color:'primary'}} />
                  </ListItem>
                ))}
              </List>              
            </Drawer>
        </div>     
        <div id="header" className={classes.header}> 
          {this.state.userLogged===null && <div id="buttonLogin" style={{position: paintMobile?'inherit':''}}>
            <LoginFlag showAppError={(str,callback) => {this.showAppError(str,callback)}}
            showNoConfirmed={this.showNoConfirmed} setCurrentScreen={this.setCurrentScreen} expandedObj={this} />
          </div>}
          {this.state.userLogged!==null && <div id="buttonLogin" style={{position: paintMobile?'inherit':''}}>
            <div id="howToButton" onClick={() => {this.closeSession()}}>
              <div id="howToButtonText">
                Cerrar sesión
              </div>                  
            </div>               
          </div>}
          {!paintMobile && <Fragment>
            {/* <div id="logoGame">
              <div id="logoGameInt"></div>
            </div> */}
            <div id="buttons">
                <div id="howToButton" onClick={() => {this.setCurrentScreen("ranking")}}>
                  <div id="howToButtonText">
                    Ranking
                  </div>                  
                </div>
                <div id="howToButton" onClick={() => {this.setCurrentScreen("premios")}}>
                  <div id="howToButtonText">
                    Premios
                  </div>                  
                </div>
                {hasMecanica && <div id="howToButton" onClick={() => {
                      /*this.openPopUp("help")*/
                      /*window.dispatchEvent(new CustomEvent("rankingClicked"));*/
                      console.log('Que show a true');
                      this.setState({showMecanica:true});
                    }}>
                  <div id="howToButtonText">
                    Mecánica
                  </div>                   
                </div>}
                {this.state.hasGanadores && <div id="howToButton" onClick={() => {
                      this.setCurrentScreen("ganadores");
                    }}>
                  <div id="ganadoresButtonText">
                    Ganadores
                  </div>                   
                </div>}
            </div>            
          </Fragment>}
        </div>
                    
        {/*<div id="center" className="center" style={styleForCenter}>
             {!paintMobile && <div id="lateralButtons">
              <div id="lateralButtonsLeft">
                <div id="rankingButton" onClick={() => {this.openPopUp("ranking")}} />
              </div>          
            </div>}  
            <div id="gameButtonsContainer">
                <div className="gameButtons" onClick={() => {this.openGame(1)}}>JUEGO 1</div>
                <div className="gameButtons" onClick={() => {this.openGame(2)}}>JUEGO 2</div>
            </div>            
        </div>*/}
        {showPreloader && <CircularProgress id="circLoader" size={50}></CircularProgress>}
        <Fragment>
            <div id="footer" className="footer">
                <div id="footer_info">
                  <div className="footer_info_item">Copyright © 2024 INDURAMA. Todos los derechos reservados</div>
                  <div className="footer_info_item dot">.</div>
                  <a className="footer_info_item footer_link" href="https://masqueuntv.com/assets/TerminosYCondiciones__LG.pdf" target="_blank">Términos y condiciones</a>
                  <div className="footer_info_item dot">.</div>
                  <a className="footer_info_item footer_link" href="https://www.indurama.com/" target="_blank">www.indurama.com</a>
                </div>
                <div id="footer_social">
                  <a id="social_instagram" className="but_social" href="https://www.instagram.com/indurama_/" target="_blank"/>
                  <a id="social_fb" className="but_social" href="https://www.facebook.com/indurama.sudamerica" target="_blank"/>
                  <a id="social_twitter" className="but_social" href="https://twitter.com/indurama" target="_blank"/>
                </div>
                
            </div>            
        </Fragment>

        {this.state.showLoader && <div id="loaderDiv">
            <CircularProgress size={50} className={classes.circular} />
        </div>}
        {this.state.showGame1 && <Game1 />}
        {this.state.showGame2 && <Game2 />}
        <div data-orientation="landscape" className="orientation-msg-container"><p className="orientation-msg-text">Por favor rota tu dispositivo</p></div>
      </MuiThemeProvider>
      </div>
    );
  }

  getJsonFromUrl(url) {
      if(!url) url = window.location.search;
      var query = url.substr(1);
      var result = {};
      query.split("&").forEach(function(part) {
        var item = part.split("=");
        result[item[0]] = decodeURIComponent(item[1]);
      });
      return result;
    }


  createGame() {        
      //console.count("create");
      //if(game!==undefined) game.destroy();

      //document.getElementById("game").innerHTML = ""

      //game = new Phaser.Game(config);      
  }
}

App.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default (withStyles(styles,{ withTheme: true })(App));