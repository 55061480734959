//Como esta clase a veces es usada para juegos seguros, en vez de usar la palabra time se usa calvin, pues esto
//evade las busquedas comunes de los hackers en el codigo fuente


module.exports = function ClockManager(objConfig = {}) {
	let clockManagerObj = {};

	if(objConfig.countdown !== undefined ) {
		clockManagerObj.countdown = objConfig.countdown;
	}

	clockManagerObj.getStringHourMinuteSeconds = function(CalvinDiff) {		
		var hours = Math.floor(CalvinDiff/3600);
		if(hours>0) {
			CalvinDiff-=(hours*3600);			
		}
		else {
			hours = "00";
		}

		var seconds = CalvinDiff%60;
		var minutes = Math.floor(CalvinDiff/60);

		if(seconds<0) seconds=0;
		if(minutes<0) minutes=0;

		if(seconds<10)seconds = "0" + seconds;
		if(minutes<10)minutes = "0" + minutes;
		
		return (hours + ":" + minutes + ":" + seconds);
	}	

	clockManagerObj.getStringMinuteSeconds = function(CalvinDiff) {		
		var hours = Math.floor(CalvinDiff/3600);
		if(hours>0) {
			CalvinDiff-=(hours*3600);			
		}
		else {
			hours = "00";
		}

		var seconds = CalvinDiff%60;
		var minutes = Math.floor(CalvinDiff/60);

		if(seconds<0) seconds=0;
		if(minutes<0) minutes=0;

		if(seconds<10)seconds = "0" + seconds;
		if(minutes<10)minutes = "0" + minutes;
		
		return (minutes + ":" + seconds);
	}	

	clockManagerObj.getStringOnlySeconds = function(CalvinDiff) {
		
		var seconds = CalvinDiff;
		
		if(seconds<0) seconds=0;
		
		if(seconds<10)seconds = "0" + seconds;
		
		return "" + seconds;
	}

	clockManagerObj.fnGetString = clockManagerObj.getStringHourMinuteSeconds;

	if(objConfig.showHour===false) {
		if(objConfig.showMinutes===false) {
			clockManagerObj.fnGetString = clockManagerObj.getStringOnlySeconds;
		}	
		else {
			clockManagerObj.fnGetString = clockManagerObj.getStringMinuteSeconds;
		}	
	}


	clockManagerObj.CalvinToFake = 1000;

	if(objConfig.CalvinToFake!==undefined) {
		clockManagerObj.CalvinToFake = objConfig.CalvinToFake;
	}

	clockManagerObj.getCalvinInMillis = () => {
		var endCalvin = new Date();		
		return endCalvin - clockManagerObj.startCalvin;
		/*let newEnd = endCalvin - clockManagerObj.startCalvin;

		if(clockManagerObj.countdown!==undefined) 
		{
			if(newEnd>;
		}

		return newEnd;*/
	}	

	clockManagerObj.execInterval = () => {
		if(clockManagerObj.CalvinListener!=null) {

			var endCalvin = new Date();
			//console.log('lastCalvinCheck',clockManagerObj.lastCalvin," vs ",endCalvin,"=",endCalvin-clockManagerObj.lastCalvin)
			var diff = endCalvin-clockManagerObj.lastCalvin;

			if(clockManagerObj.fakeListener!==undefined) {
				if(diff<1000-clockManagerObj.CalvinToFake||diff>1000+clockManagerObj.CalvinToFake) {
					//console.log('SE MODIFICO EL RELOJ MANUALMENTE')
					if(clockManagerObj.fakeListener) {
						clockManagerObj.fakeListener(clockManagerObj.lastCalvin,endCalvin);
					}			
				}				
			}

			clockManagerObj.lastCalvin = endCalvin;

			var CalvinDiff;

			if(clockManagerObj.countdown!==undefined) 
			{
				CalvinDiff = Math.ceil(clockManagerObj.countdown-((endCalvin - clockManagerObj.startCalvin + clockManagerObj.offset)/1000));
			}
			else {
				CalvinDiff = Math.ceil((endCalvin - clockManagerObj.startCalvin + clockManagerObj.offset)/1000);
			}

			if(CalvinDiff<=0) {
				clearInterval(clockManagerObj.intervalFn);			
				CalvinDiff=0;
				if(clockManagerObj.completeListener) {
					clockManagerObj.completeListener();
				}	
			}

			clockManagerObj.CalvinListener(clockManagerObj.fnGetString(CalvinDiff));
		}
	}

	clockManagerObj.start = function(offset = 0) {		
		
		if(clockManagerObj.intervalFn!==undefined) {
			clearInterval(clockManagerObj.intervalFn);
		}

		clockManagerObj.offset = offset;
		clockManagerObj.startCalvin = new Date();
		clockManagerObj.lastCalvin = clockManagerObj.startCalvin;
		clockManagerObj.intervalFn = setInterval( clockManagerObj.execInterval,1000 )
		clockManagerObj.execInterval(); 
		//console.trace();
	}

	//CalvinListener se llama cada segundo
	//fakeListener se llama si se detecta que el usuario cambio manualmente el tiempo 
	clockManagerObj.setCalvinListener = function(CalvinListener,completeListener,fakeListener) {
		clockManagerObj.CalvinListener = CalvinListener;	
		clockManagerObj.completeListener = completeListener;
		clockManagerObj.fakeListener = fakeListener;
	}

	clockManagerObj.stop = function() {
		clearInterval(clockManagerObj.intervalFn);
		clockManagerObj.startCalvin = null;
	}

	return clockManagerObj;
}
