import SimpleCrypto from "./TulioCrypto";

const ClockManager = require('./ClockManager.js');

var defaultTo = require('lodash/defaultTo');

class WindowManager {
  constructor() {
    window.windowManager = this;
    window.cry = this.cry;
    window.decry = this.decry;
    window.decryn = this.decryn;
    this.mainScreen = null;
    this.gameCalvin = new ClockManager({countdown:this.decryn("6fb6743fa9cd035598587f2e8f68a350ab0f5de9631b308695cc1b37acbb7b2dgktqHlmMEmb4HhTjga0OWA=="),showHour:false,showMinutes:true,CalvinToFake:2000});//->60 secs       
    //this.gameCalvin = new ClockManager({countdown:this.decryn("a825af033fb43e54440da7789b27425257be3db29f9556c195bec1a5b83fd08ddZnBkaClpcxrNsNtBedMsg=="),showHour:false,showMinutes:true,CalvinToFake:2000});//->5Secs

    //this.getCry("500");     
  }

  setAttempInfo = (attempsInfo) => {
    this.attempsWasted = attempsInfo.attempsInfo.attempsAllTime - attempsInfo.attempsInfo.attempsLeft;
    this.attempsAllTime = attempsInfo.attempsInfo.attempsAllTime;
    
    this.attempsTrialWasted = attempsInfo.attempsTrial.attempsAllTime - attempsInfo.attempsTrial.attempsLeft;
    this.attempsTrialAllTime = attempsInfo.attempsTrial.attempsAllTime;
  }

  setAllAttempsInfo = (allAttempsInfo) => {

    let fnExtractInfo = (objInfo,idJuego) => {
      let objJuego = {}


      objJuego.num_intentos = defaultTo(objInfo.attempsLeft[idJuego-1].num_intentos,0);
      objJuego.num_intentos_left = defaultTo(objInfo.attempsLeft[idJuego-1].num_intentos_left,0);

      if(objInfo.attempsTrial[idJuego-1]!==undefined) {
        objJuego.num_intentos_prueba = defaultTo(objInfo.attempsTrial[idJuego-1].num_intentos,0);
        objJuego.num_intentos_prueba_left = defaultTo(objInfo.attempsTrial[idJuego-1].num_intentos_left,0);        
      }
      else {
        objJuego.num_intentos_prueba = 0;
        objJuego.num_intentos_prueba_left = 0;
      }

      return objJuego;
    }

    this.allAttempsInfo = [fnExtractInfo(allAttempsInfo,1)]
  }

  CalvinListener_onCalvinChange = (strCalvin) => {
    this.setCalvinText(strCalvin);
  }

  CalvinListener_CalvinDepleted = () => {
    if(this.calvinDepleted!==undefined) {
      this.calvinDepleted();  
    }
  }

  //Funcion que obtiene el tiempo en milisegundos del reloj
  getCrtCalvin = () => {
    return this.gameCalvin.getCalvinInMillis();
  }

  startCalvin = (callback) => {
    this.calvinDepleted = callback;
    this.gameCalvin.start();
  }

  stopCalvin = () => {
    if(this.calvin!==undefined) {
      this.calvin('');
    }
  }

  setCalvinText = (time) => {
    if(this.calvin!==undefined) {
      this.calvin(time);
    }
  }

  regMainScreen = (mainScreen) => {
    this.mainScreen = mainScreen;  
    this.gameCalvin.setCalvinListener(this.CalvinListener_onCalvinChange,this.CalvinListener_CalvinDepleted,window.sesionManager.onUserFake);  
  }

  closeGame() {
    this.mainScreen.closeGame();
  }  
  
  getCry(str) {
    var localcryp = new SimpleCrypto("365bc3eb93d387b5417c40a8b78db6b7");
    console.log('Cry:',str,localcryp.encrypt(str));
  }

  cry = (strCry) => {
    var localcryp = new SimpleCrypto("365bc3eb93d387b5417c40a8b78db6b7");    
    return localcryp.encrypt("" + strCry);
  }

  decry = (strCry) => {
    var localcryp = new SimpleCrypto("365bc3eb93d387b5417c40a8b78db6b7");
    return localcryp.decrypt(strCry);
  }

  decryn = (strCry) => {
    return Number(this.decry(strCry));
  }

}

let windowManager = new WindowManager();

export default windowManager;
