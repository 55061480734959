import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';

import Screen from './Screen'


const styles = theme => ({  

    mainDiv: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
      height: "100%",
    }, 

    imgDiv: {
      width: "80%",
      position: "relative",
      top: "87px",
      maxWidth: "400px",
    },

});

class Ganadores extends Component {
  
  state = {    
    showLoader: false,
  }

  
  render() {
    
    const { classes } = this.props;

    return (
      <Fragment>
        <Screen back={this.closePopUp} showLoader={this.state.showLoader} contentCss={{
          padding: "0 2%",
          width: "80%",
          margin: "10vh auto",
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}>
          <div className={classes.mainDiv}>                  
            <div id="imageGanadores" className={classes.imgDiv}>
              <img src="assets/logo_juego.png" alt="" style={{width:'100%'}}/>

              <h2 className='text-center text-white'>Al finalizar el concurso, se anunciará al afortunado ganador.</h2>
            </div>            
          </div>
        </Screen>
        <div id="botAtras" onClick={() => {
            this.props.backFromScreen()
          }}></div>
      </Fragment>
    );
  }

}

export default (withStyles(styles,{ withTheme: true })(Ganadores));