import React, { Component, Fragment } from "react";

import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core/styles";

import PopUp from "./PopUp";
import "./Ingresar.css";

import { sesionManager } from "./SesionManager";

const request = require("superagent");

const styles = theme => ({
  textField: {
    width: "100%"
  },

  cssLabel: {
    color: "#f8e926",
    "&$cssFocused": {
      color: "#f8e926"
    }
  },

  cssFocused: {
    color: "#f8e926"
  },

  cssOutlinedInput: {
    color: "#f8e926"
  },
  notchedOutline: {
    borderColor: "#f8e926"
  },

  cssCheck: {
    color: "#f8e926 !important"
  },

  button: {
    margin: theme.spacing.unit,
    backgroundColor: "#b718e6",
    borderRadius: 30,
    "&:hover": {
      backgroundColor: "#691881"
    }
  }
});

class UseCode extends Component {
  state = {
    code: "",
    showLoader: false,
    opened: true
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value
    });
  };

  closePopUp = () => {
    this.props.back();
  };

  closePopUpWithAnim = () => {
    this.setState({ opened: false });
  };

  hitEnter = ev => {
    if (ev.key === "Enter") {
      this.useCode();
      ev.preventDefault();
    }
  };

  useCode = () => {
    let code = this.state.code;

    let error = "";

    if (code.length === 0) {
      error = "Por favor ingresa el código a utilizar";
    }

    if (error !== "") {
      this.props.showAppError(error);
      return;
    }

    this.setState({ showLoader: true });

    request
      .post(process.env.REACT_APP_SERVER_URL + "use_code.php")
      .query({ code: code, id_user: sesionManager.idUser })
      .end((err, res) => {
        this.setState({ showLoader: false });
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          console.log(jsonRes, jsonRes["success"], jsonRes.success);
          if (jsonRes.success === "false") {
            if (jsonRes.regError === "true") {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError(
                "Ha ocurrido un error al intentar usar el código, si el error persiste contactate al correo " +
                  process.env.REACT_APP_SUPORT_MAIL +
                  "\n\n[error: " +
                  jsonRes["msg"] +
                  "]"
              );
            }
          } else {
            sesionManager.assignCode(jsonRes.code_date);
            this.closePopUpWithAnim();
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  };

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <PopUp
          back={this.closePopUp}
          logo={"none"}
          showLoader={this.state.showLoader}
          opened={this.state.opened}
        >
          <Fragment>
            <form>
              <TextField
                id="outlined-email-input"
                label="Código a utilizar"
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,
                    focused: classes.cssFocused
                  }
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput
                  }
                }}
                margin="normal"
                variant="outlined"
                value={this.state.code}
                onChange={this.handleChange("code")}
              />
            </form>

            <div id="buttonRegContainer">
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                onClick={() => {
                  this.useCode();
                }}
              >
                Usar código
              </Button>
            </div>
          </Fragment>
        </PopUp>
      </Fragment>
    );
  }
}

export default withStyles(styles, { withTheme: true })(UseCode);
