import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import GameCalvin from './GameCalvin';

import { sesionManager } from './SesionManager'

import Phaser from 'phaser';

import PhaserUtils from './utils/phaserUtils'

const styles = theme => ({
  fulldiv: {
    width: "100vw",
    height: "100vh",
    backgroundColor: '#000E',
    position: "absolute",
    top: "0",
    display: "flex",
    alignItems: "center",
  },

  divGame: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",    
    width: "100%",
  },

});

let multiplierScale = 1;
let multiplierImagesScale = 340/720;

let widthScene = 1366*multiplierScale;
let heightScene = 771*multiplierScale;

let showPreloader = false;

var game;

var ballLaunched = false;

let textTulio;
let textMaxTulio;
let textCalvin;
let backGame;
let textTulio2;
let textRan;
let contEndGame;

let jsx;

let fnRefreshScore = () => {
  console.log('Ejecuto refresh en juego2');
  textRan.setText("PUESTO: "+sesionManager.rankingData[1].myPos);
}

function paintRegularBackGround(sceneToPaint) {
    let back = null;
    back = sceneToPaint.add.image(widthScene/2, heightScene/2, 'background_menu');
    //back.setScale(multiplierImagesScale*multiplierScale)    
}

function restartGame() {
    ballLaunched = false;
}

function makeButtonBlink(scene,buttonObj) {
    scene.tweens.add({
        targets: [ buttonObj ],
        scaleX: 1.1,
        scaleY: 1.1,
        duration: 500,
        repeat: -1,
        yoyo: true,
        ease: 'Sine.easeInOut',
        onComplete: function () {},
    });
}

var Menu = new Phaser.Class({

    Extends: Phaser.Scene,

    initialize:
    function Menu ()
    {
        Phaser.Scene.call(this, { key: 'menu', active: true });
    },

    preload: function ()
    {        
       this.load.image('background_menu', 'assets/game_21/backMenu.png');
       this.load.image('background_juego', 'assets/game_21/backGame.png');
       this.load.image('play', 'assets/game_21/but_play.png');
       this.load.image('but_back', 'assets/game_21/but_back.png');
       this.load.image('gameover', 'assets/game_21/gameover.png');
       this.load.image('but_home', 'sprites/but_home.png');
       this.load.spritesheet('full', 'assets/game_21/but_fullscreen.png', { frameWidth: 63, frameHeight: 64 });      
    },

    startGame: function () 
    {
        console.log('Entra restar con ',this);
    },

    create: function ()
    {
        //this.scene.start('juego');

        paintRegularBackGround(this);
        
        
        let playBut = this.add.image(widthScene/2, heightScene/8*6.5, 'play');
        makeButtonBlink(this,playBut)

        let backBut = this.add.image(15, 20, 'but_back');
        backBut.setOrigin(0); 

        this.botFull = this.add.sprite(15 + backBut.width + 15, backBut.y, 'full');
        this.botFull.setOrigin(0);
        this.botFull.setFrame(1);

        PhaserUtils.setAsButton(backBut,() => { 
          jsx.removeOrientationListener();
          window.windowManager.closeGame();
        })

        PhaserUtils.setAsButton(this.botFull,() => { 
          this.scale.startFullscreen();
        })

        PhaserUtils.setAsButton(playBut,() => { 
            let sesion_prueba=0;

            if(window.windowManager.allAttempsInfo[1].num_intentos_prueba_left>0) {
                sesion_prueba=1;
                window.windowManager.sesionPrueba = true;
            }
            else {
                window.windowManager.sesionPrueba = false;
            }

            //this.startGame();
            //this.scene.start('juego');
            sesionManager.getGameSession(() => {
                
                if(window.windowManager.sesionPrueba) {
                    window.windowManager.allAttempsInfo[1].num_intentos_prueba_left--;
                }
                else {
                    window.windowManager.allAttempsInfo[1].num_intentos_left--;    
                }

                window.sesionManager.collectMessage("Init",{});
                console.log('Inicia nuevo juego')
                this.scene.start('juego');                                        
            },2)
        })        
    }        
});

var GameOver = new Phaser.Class({

    Extends: Phaser.Scene,

    initialize:
    function GameOver ()
    {
        Phaser.Scene.call(this, { key: 'gameOver', active: false });
    },

    preload: function ()
    {
        
    },

    create: function ()
    {
        
    }        
});

var Juego = new Phaser.Class({

    Extends: Phaser.Scene,

    initialize:
    function Juego ()
    {
        //console.log('A')
        Phaser.Scene.call(this, { key: 'juego', active: false });
    },

    preload: function ()
    {
        backGame = this.add.image(widthScene/2, heightScene/2, 'background_juego');
        
        this.load.spritesheet('ball', 'assets/game_21/ballstore-sheet0.png', { frameWidth: 102, frameHeight: 102 });
        this.load.image('shadow', 'assets/game_21/ballshadow-sheet0.png');
        this.load.image('dots', 'assets/dots.png');        
    },

    update: function () 
    {
        this.shadow.x = this.ball.x;
         
    },

    create: function ()
    {
        this.maxTulioId = -1;
        this.playing = true;
        this.clickL = false;

        var cont = this.add.container();
        cont.x = widthScene - 169;
        cont.y = 105;

        textTulio = this.add.text(180, 120, '0', { fontFamily: 'Titillium Web', fontSize: 55*multiplierScale, color: '#ffffff' }).setShadow(2, 2, "#333333", 2, false, true);                
        textTulio.setOrigin(0.5);

        textMaxTulio = this.add.text(180, 150, 'Mejor puntaje: 0', { fontFamily: 'Titillium Web', fontSize: 20*multiplierScale, color: '#ffffff' }).setShadow(2, 2, "#333333", 2, false, true);                
        textMaxTulio.setOrigin(0.5);
      
        textCalvin = this.add.text(0, 45, '00:00', { fontFamily: 'Titillium Web', fontSize: 75*multiplierScale, color: '#FFF'}).setShadow(2, 2, "#333333", 2, false, true)
        textCalvin.setOrigin(0.5);

        cont.add(textCalvin);

        this.matter.world.setBounds(0, 0, widthScene, heightScene, 32, true, true, true, true);

        let floor = this.matter.add.sprite(widthScene/2, heightScene-32);
        floor.setStatic(true)
        floor.displayWidth = widthScene;
        floor.body.label = "floor";
        console.log(floor);

        console.log(this.matter);

        this.ball = this.matter.add.sprite(100, floor.y-75, 'ball');
        this.ball.setFrame(3);
        this.ball.setCircle();
        this.ball.setFriction(0.005);
        this.ball.setBounce(0.7);
        this.ball.setScale(multiplierImagesScale*multiplierScale*1.5);

        this.shadow = this.add.sprite(widthScene/2, floor.y-16, 'shadow');
        this.shadow.setScale(multiplierImagesScale*multiplierScale*0.8);        

        //Cortina de gameOver

        contEndGame = this.add.container();
        contEndGame.x = widthScene/2;
        contEndGame.y = heightScene/2;

        let backGameOver = this.add.image(0, 0, 'gameover');
        backGameOver.setOrigin(0.5);

        let but_home = this.add.image(160, 120, 'but_home');
        but_home.setOrigin(0.5);

        textTulio2 = this.add.text(-160, -30, 'TIENES: 21 PTS', { fontFamily: 'Titillium Web', fontSize: 30*multiplierScale, color: '#ffffff' })
        textRan = this.add.text(-160, 75, 'PUESTO: ---', { fontFamily: 'Titillium Web', fontSize: 30*multiplierScale, color: '#ffffff' })
        
        PhaserUtils.setAsButton(but_home,() => { 
          //Es un juego de prueba
          if(window.windowManager.sesionPrueba) {
            //se acabaron los intentos
            if(window.windowManager.allAttempsInfo[1].num_intentos_prueba_left==0) {
                //Colocar cortinilla y luego volver al menu
                //"Has terminado las rondas de prueba, ahora comenzaras a gastar tus rondas de juego, si quieres mas rondas de prueba debes ingresar mas facturas"
            }
            else {
             this.scene.start('menu');
            }
          }
          else {
            if(window.windowManager.allAttempsInfo[1].num_intentos_left==0) {
                window.windowManager.closeGame();
            }
            else {
                this.scene.start('menu');
            }             
          }
        })

        contEndGame.add(backGameOver);
        contEndGame.add(textTulio2);
        contEndGame.add(textRan);
        contEndGame.add(but_home);
        
        contEndGame.setVisible(false);

        ///------------------------------------------------------

        console.log('bounds',this.matter,this);

        //Phaser.Math.Between(100, 700), Phaser.Math.Between(-600, 0)

        this.ball.setInteractive();
        backGame.setInteractive();

        this.clicks = 0;

        backGame.on('pointerdown', (evt) => {
          this.clicks++;
        })
        

        //  The images will dispatch a 'clicked' event when they are clicked on
        this.ball.on('pointerdown', (evt) => {
            
            if(!this.playing || this.clickL) return;

            this.clicks++;

            this.ball.setVelocity(0,0);
            let force = new Phaser.Math.Vector2(Phaser.Math.Between(-20, 20)/100,-Phaser.Math.Between(10, 40)/100);
            if(!ballLaunched && force.y>-0.35) {
                force.y=-0.35;
                console.log('Reescribe la velocidad y inicial');
            }                

            force.x*=multiplierImagesScale*multiplierScale*1.5;
            force.y*=multiplierImagesScale*multiplierScale*1.5;
            
            window.sesionManager.collectMessage("BClick",{force,x:this.ball.x,y:this.ball.y});

            console.log('force',force,evt);
            this.ball.setAngularVelocity(force.x*1.5)
            //console.log(evt.getDistance());
            this.ball.applyForce(force);
            this.clickL = true;

            setTimeout(() => {this.clickL = false},200);

            sesionManager.setCurrentTulio(sesionManager.getCurrentTulio() + window.decryn("5cb0961ab5fc991aa3c85d734e5803c53670942dc9c7317850a880774cfe6447AkBEBur8rHDsQO5Lt5OJWQ=="));
            textTulio.setText(sesionManager.getCurrentTulio());            
            ballLaunched = true;
          }
        , this);

        var getTulio = (deleteScoreText = true) => {
          if(this.maxTulioId==-1) {
            this.maxTulioId = sesionManager.getCurrentCryTulio();
          }
          else {
            if(sesionManager.getCurrentTulio()>sesionManager.getDecrypTulio(this.maxTulioId)) {
              this.maxTulioId = sesionManager.getCurrentCryTulio();   
            }
          }

          textMaxTulio.setText("Mejor puntaje: "+sesionManager.getDecrypTulio(this.maxTulioId));

          sesionManager.setCurrentTulio(0);
          if(deleteScoreText) textTulio.setText("0");  
          ballLaunched = false;
        }

        this.matter.world.on('collisionstart', (event, bodyA, bodyB) => {            
            if(ballLaunched && (bodyA.label==="floor" || bodyA.label==="floor")) {
              getTulio();
            }
        });

        window.windowManager.calvin = (strCalvin) => {
          textCalvin.setText(strCalvin);
        };

        window.windowManager.startCalvin(() => {

          window.removeEventListener("scoresRefreshed",fnRefreshScore);
          window.addEventListener("scoresRefreshed",fnRefreshScore);

          getTulio(false);
          this.playing = false;
          sesionManager.setCurrentTulio(sesionManager.getDecrypTulio(this.maxTulioId));

          window.sesionManager.collectMessage("Over",{clicks:this.clicks});
          window.sesionManager.endGame();
          window.windowManager.attempsWasted++;

          console.log('Tiene '+(window.windowManager.attempsAllTime - window.windowManager.attempsWasted) + ' intentos restantes');
          
          textTulio2.setText("TIENES: "+sesionManager.getDecrypTulio(this.maxTulioId)+" PTS");
          contEndGame.setVisible(true);
        });
    }
});


class Game2 extends Component {
  state = {    
  };

  closePopUp = () => {
    this.props.back();
  };

  closePopUpWithAnim = () => {
    this.setState({ opened: false });
  };

  componentDidMount() {     
    jsx = this;   
    this.createGame();
    console.log('Llama este'); 

    window.addEventListener("orientationchange", this.onOrientationChange);                

    var h = this.getSize("Height");
    var w = this.getSize("Width");
    this._checkOrientation(w,h);
  }

  removeOrientationListener = () => {
    window.removeEventListener("orientationchange", this.onOrientationChange);
  }

  getSize = (Name) => {
        var size;
                var name = Name.toLowerCase();
                var document = window.document;
                var documentElement = document.documentElement;
                if (window["inner" + Name] === undefined) {
        // IE6 & IE7 don't have window.innerWidth or innerHeight
        size = documentElement["client" + Name];
        }
        else if (window["inner" + Name] != documentElement["client" + Name]) {
        // WebKit doesn't include scrollbars while calculating viewport size so we have to get fancy

        // Insert markup to test if a media query will match document.doumentElement["client" + Name]
        var bodyElement = document.createElement("body");
                bodyElement.id = "vpw-test-b";
                bodyElement.style.cssText = "overflow:scroll";
                var divElement = document.createElement("div");
                divElement.id = "vpw-test-d";
                divElement.style.cssText = "position:absolute;top:-1000px";
                // Getting specific on the CSS selector so it won't get overridden easily
                divElement.innerHTML = "<style>@media(" + name + ":" + documentElement["client" + Name] + "px){body#vpw-test-b div#vpw-test-d{" + name + ":7px!important}}</style>";
                bodyElement.appendChild(divElement);
                documentElement.insertBefore(bodyElement, document.head);
                if (divElement["offset" + Name] == 7) {
        // Media query matches document.documentElement["client" + Name]
        size = documentElement["client" + Name];
        }
        else {
        // Media query didn't match, use window["inner" + Name]
        size = window["inner" + Name];
        }
        // Cleanup
        documentElement.removeChild(bodyElement);
        }
        else {
        // Default to use window["inner" + Name]
        size = window["inner" + Name];
        }
        return size;
  };

  onOrientationChange = () => {
    if (window.matchMedia("(orientation: portrait)").matches) {
        setTimeout(() => {
          var h = this.getSize("Height");
          var w = this.getSize("Width");      
          this._checkOrientation(w,h)
        },800);
    }

    if (window.matchMedia("(orientation: landscape)").matches) {      
      setTimeout(() => {
        var h = this.getSize("Height");
        var w = this.getSize("Width");      
        this._checkOrientation(w,h)
      },800);        
    }
  }

  _checkOrientation = (iWidth,iHeight) => {

    console.log('Tiene como orien ',window.$("#game2container").length,document.getElementById("game2container"));
    if(window.$("#game2container").length==0 || document.getElementById("game2container")==null) {
        window.$(".orientation-msg-container").css("display","none");           
        return;
    }
    

    console.log('Tiene w',iWidth,'vs',window.innerWidth,'h',iHeight,window.$(".orientation-msg-container").attr("data-orientation"));

      if( iWidth>iHeight ){ 
          if( window.$(".orientation-msg-container").attr("data-orientation") === "landscape" ){
              window.$(".orientation-msg-container").css("display","none");
          }else{
              window.$(".orientation-msg-container").css("display","block");
          }  
      }else{
          if( window.$(".orientation-msg-container").attr("data-orientation") === "portrait" ){
              window.$(".orientation-msg-container").css("display","none");
          }else{
              window.$(".orientation-msg-container").css("display","block");
          }   
      }
  }
  

  createGame() {        
      console.count("create");
      if(game!==undefined) game.destroy();

      //document.getElementById("game2").innerHTML = ""
      
      var canvas2 = document.getElementById("canvas2");      

      var config = {
          canvas: canvas2,
          type: Phaser.CANVAS,
          width: widthScene,
          height: heightScene,
          parent: 'game2',
          backgroundColor: '#000',
          physics: {
              default: 'matter',            
              matter: {
                  debug: false,
              },
          },
          scene: [
              Menu,Juego,GameOver                            
          ]          
      };

      game = new Phaser.Game(config);    
  }

  
  render() {
    const { classes } = this.props;

    return (
      <div id="game2container" className={classes.fulldiv}>
        <GameCalvin />
        <div id="game2" className={classes.divGame} style={{visibility:showPreloader?"hidden":"visible"}}>
          <canvas id="canvas2" className='ani_hack' width="1366" height="771"> </canvas>
        </div>
      </div>       
    );    
  }
}

export default withStyles(styles, { withTheme: true })(Game2);
