import React, { Component,Fragment } from 'react';

import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

import { sesionManager } from './SesionManager'

const sha1 = require('js-sha1');
const request = require('superagent');


const styles = theme => ({  
    labelOlvido: {
      fontFamily: "Titillium Web",
      fontSize: "12px",
      color: "white",
      textDecoration: 'underline',
      cursor: 'pointer',
    },
    divOlvido: {
      display: "flex",    
      justifyContent: "center",
      marginTop: "10px",
    },

    textField: {
      width: "100%",
      height: "24px",
    },

    labelText: {
      color: 'white',
      fontFamily: 'Titillium Web',
      textAlign: "center",
      margin: "7px 0px 3px",
    },

    cssLabel: {
      fontFamily: 'Titillium Web'
    },

    cssFocused: {
      //color: "#f8e926",
    },

    cssOutlinedInput: {
      fontFamily: 'Titillium Web',    
      fontSize: 14, 
    },

    notchedOutline: {
      borderColor: "#f8e926",
    },

    cssCheck: {
      color: "#f8e926 !important",         
    },

    button: {
      margin: theme.spacing.unit,
      fontFamily: 'Titillium Web',  
      color: 'white',
      cursor: 'pointer',
    },

    buttonReg: {
      margin: theme.spacing.unit,
      backgroundColor: "#109cea",
      borderRadius: 30,
    },

    expandedFlag: {
      transition: "top 0.2s",
      top: '0 !important',
    },
    
    noExpandedFlag: {
      top: -150,
      transition: "top 0.2s",
      '&:hover': {
        top: '-125px !important',
      },  
      '&:hover div': {
        top: 157,
      },
    },

    noExpandedLabel: {
      top: 169,
      fontFamily: "'Titillium Web'",
      color: "white",
      position: "absolute",      
      textAlign: "center",
      width: "calc( 100% - 20px )", 
      transition: "top 0.2s", 
    },


});

class LoginFlag extends Component {
    
  state = {
    mail: '',
    password: '',
    showLoader:false,    
    contentType: 'regular', //forgetData, regular,restorePass,afterResponse
    opened: true,        
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };  

  hitEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.proceed();
      ev.preventDefault();
    }
  }

  proceed = () => {
    if(this.state.contentType==="restorePass") {
      this.recuperarPass();
    } else {
      this.logUser();        
    }
  }

  closePopUp = () => {
    this.props.back();
  }

  closePopUpWithAnim = () => {
    this.setState({opened:false})    
  }

  showContent = (contentType) => {
    this.props.setCurrentScreen(contentType);
    this.clicCloseFlag();
  }

  logUser = () => {
    
    let correo = this.state.mail;
    let pass = this.state.password;
    
    let error = "";

    if(!sesionManager.validateEmail(correo)) {
      error = "Lo sentimos, el correo electronico no es valido";
    }
    
    if(correo.length===0||pass.length===0) {
      error = "Lo sentimos, todos los campos son obligatorios";
    }
    
    if(error!=="") {
      this.props.showAppError(error);
      return;      
    }
    
    this.setState({showLoader:true})

    request
      .post(process.env.REACT_APP_SERVER_URL+'login_user.php')
      .query({correo:correo,pass:sha1(pass)})
      .end((err, res) => {
        this.setState({showLoader:false})
        if(err) {
          console.error('Error, posiblemente no haya conexión con el servidor', err);
          return null;
        }
        if(res && res.text) {
          //console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);          
          //console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if(jsonRes.success==="false") {
            if(jsonRes.regError==="true") {
              if(jsonRes.errorType==="NO_CONFIRMED") {
                this.props.showNoConfirmed(correo);
              } else {
                this.props.showAppError(jsonRes.msg);                
              }
            } else {
              this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
            }
          }
          else {
            sesionManager.checkLoggedUserInfo(jsonRes);
            sesionManager.setCookie("mail",correo);
            sesionManager.setCookie("hash",sha1(pass));            
          }
        } else {
          console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
        }
      });    
  }

  recuperarPass() {

    let score = this.state.mail;
    
    let error = "";

    if(!sesionManager.validateEmail(score)) {
      error = "Lo sentimos, el correo electronico no es valido";
    }
    
    if(score.length===0) {
      error = "Lo sentimos, todos los campos son obligatorios";
    }
    
    if(error!=="") {
      this.props.showAppError(error);
      return;      
    }
    
    this.setState({showLoader:true})

    request
      .post(process.env.REACT_APP_SERVER_URL+'ask_restore_pass.php')
      .query({correo:score})
      .end((err, res) => {
        this.setState({showLoader:false})
        if(err) {
          console.error('Error, posiblemente no haya conexión con el servidor', err);
          return null;
        }
        if(res && res.text) {
          //console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);          
          //console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if(jsonRes.success==="false") {
            if(jsonRes.regError==="true") {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
            }
          }
          else {            
            this.setState({contentType:"afterResponse"})
          }
        } else {
          console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
        }
      });    
  } 

  clicFlag = () => {
    if(!this.props.expandedObj.state.loginExpanded) {
      this.props.expandedObj.setState({loginExpanded: true});
    }
  }

  clicCloseFlag = () => {
    this.props.expandedObj.setState({loginExpanded: false});    
  }
    

  render() {
    
    const { classes } = this.props;

    let expanded = this.props.expandedObj.state.loginExpanded;

    let classButton = expanded?classes.expandedFlag:classes.noExpandedFlag;

    return (
      <div id="LoginButton" className={classButton} onClick={this.clicFlag}>

        {!expanded && 
          <div className={classes.noExpandedLabel}>
            Iniciar sesión
          </div>                                  
        }

        {expanded && 
          <Fragment>
            <form>
              <div className={classes.labelText}>
                E-mail
              </div>
                <TextField
                  id="outlined-email-login-input"
                  className={classes.textField}
                  onKeyPress={this.hitEnter}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,   
                      focused: classes.cssFocused,                   
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.cssOutlinedInput,                      
                    },
                  }}
                  type="email"
                  name="email"
                  autoComplete="email"
                  margin="none"
                  variant="filled"
                  value={this.state.mail}
                  onChange={this.handleChange('mail')}
                />

                <div className={classes.labelText}>
                  Contraseña
                </div>

                <TextField
                  id="outlined-password-login-input"                
                  className={classes.textField}
                  onKeyPress={this.hitEnter}
                  InputLabelProps={{
                    classes: {
                      root: classes.cssLabel,   
                      focused: classes.cssFocused,                   
                    },
                  }}
                  InputProps={{
                    disableUnderline: true,
                    classes: {
                      root: classes.cssOutlinedInput,                      
                    },
                  }}
                  type="password"
                  autoComplete="current-password"
                  margin="none"
                  variant="filled"
                  value={this.state.password}
                  onChange={this.handleChange('password')}
                />  

                <div className={classes.divOlvido}>               
                  <div className={classes.labelOlvido} onClick={() => {this.showContent("restore_pass_first_step")}}>Olvidaste tu contraseña</div>
                </div>          
              </form>
              
              <div id="buttonRegContainer">
                <div className={classes.button} onClick={() => {this.logUser()}}>
                  Entrar
                </div>
                <div id="containerFlecha" onClick={this.clicCloseFlag}>
                  <div id="flechaArriba">              
                  </div>                  
                </div>
              </div>
            </Fragment>}       
      </div> 
    );
  }

}

export default (withStyles(styles,{ withTheme: true })(LoginFlag));