import React, { Component,Fragment } from 'react';

import Modal from '@material-ui/core/Modal';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import Grow from '@material-ui/core/Grow';

import Backdrop from '@material-ui/core/Backdrop';


const styles = theme => ({  
    modalWn: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',         
    },

    circular: {
        outline: "0px solid transparent",
        position: "absolute",
        left: "calc( 50% - 25px )",
        top: "calc( 50% )",
    },

    divScreen: {
      position: 'absolute',
      height: "100%",
      width: "100%",      
    },

    screenContainer: {      
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexDirection: "column",
      position: "absolute",
      top: "80px",
      width: "100%",
      height: "calc( 100% - 110px)",      
      overflowY: 'auto',
      overflowX: 'hidden',      
      zIndex: "1",
      [theme.breakpoints.down(800)]: {
        height: "calc( 100% - 130px)",
      }
    }


});

export class BackDropIOSWorkaround extends React.PureComponent<BackdropProps> {
    onTouchMove(event: React.TouchEvent<HTMLDivElement>): void {
      console.log('Prevent')
      event.preventDefault();
    }

    handleClose() {
      console.log('Aca')
    }

    render() {
        return (
            <Backdrop {...this.props} onTouchMove={this.onTouchMove} onClose={this.handleClose}/>
        );
    }
}

class Screen extends Component {
 
  state = {
    opened: true,
    name: '',
    mail: '',
    password: ''
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleClose = () => {
    this.setState({opened:false});    
  };

  closePopUp = () => {
    this.props.back();
  }

  render() {
    
    const { classes,opened,showLoader } = this.props;

    var screen = this.props.screen;

    if(screen===undefined) screen = "";


    let realOpened = opened!==undefined?opened&&this.state.opened:this.state.opened
    
          //onClose={this.handleClose}
    return (
      <div className={classes.divScreen}>
        <Grow in={realOpened} onExited={() => {this.closePopUp()}}>
          <div id={"screenContainer"+screen} className={classes.screenContainer} style={this.props.screenCss}>
            {this.props.children}            
          </div>
        </Grow>
        {showLoader && <div id="loaderDiv">
          <CircularProgress size={50} className={classes.circular} />
        </div>}          
      </div>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(Screen));