Object.defineProperty(exports, "__esModule", { value: true });
var CryptoJS = require("crypto-js");
var TulioCrypto = /** @class */ (function () {
    function TulioCrypto(secret) {
        if (secret === void 0)
            throw new Error('TulioCrypto object MUST BE initialised with a SECRET KEY.');

        this.encryptMethod = 'AES-256-CBC';
        this.encryptMethodLength = parseInt(this.encryptMethod.match(/\d+/)[0]);
        this.encryptKeySize = this.encryptMethodLength / 8;

        this._secret = secret;
        this._keySize = 256;
        this._iterations = 100;
    }
    TulioCrypto.generateRandom = function (length, expectsWordArray) {
        if (length === void 0) { length = 128; }
        if (expectsWordArray === void 0) { expectsWordArray = false; }
        var random = CryptoJS.lib.WordArray.random(length / 8);
        return expectsWordArray ? random : random.toString();
    };

    TulioCrypto.prototype.encrypt = function (data) {        
        var salt = CryptoJS.lib.WordArray.random(16);
        var iv = CryptoJS.lib.WordArray.random(16);
        
        //var hashKey = CryptoJS.PBKDF2(this._secret, salt, {'hasher': CryptoJS.algo.SHA512, 'keySize': (this._keySize/32), 'iterations': this._iterations});
        var hashKey = CryptoJS.PBKDF2(this._secret, salt, {'hasher': CryptoJS.algo.SHA1, 'keySize': (this._keySize/32), 'iterations': this._iterations});
        
        var encrypted = CryptoJS.AES.encrypt(data, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});        
        var encryptedString = CryptoJS.enc.Base64.stringify(encrypted.ciphertext);

        var ciphered = CryptoJS.enc.Hex.stringify(salt) + CryptoJS.enc.Hex.stringify(iv) + encryptedString;
        
        //console.log(ciphered)
        return ciphered;
        
    };
    TulioCrypto.prototype.decrypt = function (ciphered) {
        
        /*var json = JSON.parse(CryptoJS.enc.Utf8.stringify(CryptoJS.enc.Base64.parse(ciphered)));

        var salt = CryptoJS.enc.Hex.parse(json.salt);
        var iv = CryptoJS.enc.Hex.parse(json.iv);

        var encrypted = json.ciphertext;// no need to base64 decode.*/

        var salt = CryptoJS.enc.Hex.parse(ciphered.substr(0, 32));
        var iv = CryptoJS.enc.Hex.parse(ciphered.substr(32, 32));

        var encrypted = ciphered.substring(64);

        /*var iterations = parseInt(json.iterations);
        if (iterations <= 0) {
            iterations = 999;
        }*/

        var iterations = this._iterations;

        var encryptMethodLength = (this.encryptMethodLength/4);// example: AES number is 256 / 4 = 64
        var hashKey = CryptoJS.PBKDF2(this._secret, salt, {'hasher': CryptoJS.algo.SHA1, 'keySize': (encryptMethodLength/8), 'iterations': iterations});

        var decrypted = CryptoJS.AES.decrypt(encrypted, hashKey, {'mode': CryptoJS.mode.CBC, 'iv': iv});

        return decrypted.toString(CryptoJS.enc.Utf8);



        /*if (expectsObject === void 0) { expectsObject = false; }
        if (enc === void 0) { enc = CryptoJS.enc.Utf8; }
        if (ciphered == void 0)
            throw new Error('No encrypted string was attached to be decrypted. Decryption halted.');
        var salt = CryptoJS.enc.Hex.parse(ciphered.substr(0, 32));
        var initialVector = CryptoJS.enc.Hex.parse(ciphered.substr(32, 32));
        var encrypted = ciphered.substring(64);
        var key = CryptoJS.PBKDF2(this._secret, salt, {
            keySize: this._keySize / 32,
            iterations: this._iterations
        });

        console.log('CRIP:',salt,initialVector,encrypted,key)
        var decrypted = CryptoJS.AES.decrypt(encrypted, key, {
            iv: initialVector,
            //padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        });
        var data = expectsObject ? JSON.parse(decrypted.toString(enc)) : decrypted.toString(enc);
        return data;*/
    };
    TulioCrypto.prototype.encryptObject = function (object) {
        return this.encrypt(object);
    };
    TulioCrypto.prototype.decryptObject = function (string) {
        var decrypted = this.decrypt(string, true);
        var object = typeof decrypted == 'object' ? decrypted : JSON.parse(decrypted);
        return object;
    };
    TulioCrypto.prototype.setSecret = function (secret) {
        this._secret = secret;
    };
    return TulioCrypto;
}());
exports.default = TulioCrypto;