import React, { Component,Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PopUp from './PopUp'
import './Ingresar.css';

const sha1 = require('js-sha1');
const request = require('superagent');


const styles = theme => ({  
    textField: {
      width: "100%",
      color: "black",
      minWidth: 270,
      marginTop: "8px",
      height: "40px",
      backgroundColor: "white",
      display: "flex",
      justifyContent: "flex-end",
      cursor: 'text',
    },

    cssLabel: {
      color: "#f8e926",
      '&$cssFocused': {
        color: "#f8e926",
      },
    },

    cssFocused: {
      color: "#f8e926",
    },

    cssOutlinedInput: {
      color: "#f8e926",      
    },
    
    notchedOutline: {
      borderColor: "#f8e926",
    },

    cssCheck: {
      color: "#f8e926 !important",         
    },

    button: {
      margin: theme.spacing.unit,
      backgroundColor: "#b718e6",
      borderRadius: 30,
      '&:hover': {
        backgroundColor: "#691881",
      }
    },

    buttonReg: {
      margin: theme.spacing.unit,
      backgroundColor: "#109cea",
      borderRadius: 30,
    },
});

class Ingresar extends Component {
 
  state = {
    password: '',
    contentType: 'regular', //regular,success
    showLoader:false,    
    opened: true,    
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };  

  hitEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.proceed();
      ev.preventDefault();
    }
  }

  proceed = () => {    
    this.restoreUser();
  }

  closePopUp = () => {
    this.props.back();
  }

  closePopUpWithAnim = () => {
    this.setState({opened:false})    
  }

  restoreUser = () => {
    
    let hash = this.props.hash;
    let pass = this.state.password;
    
    let error = "";
      
    if(pass.length===0) {
      error = "Lo sentimos, todos los campos son obligatorios";
    }
    
    if(error!=="") {
      this.props.showAppError(error);
      return;      
    }
    
    this.setState({showLoader:true})

    console.log('enviar ',hash,sha1(pass))

    request
      .post(process.env.REACT_APP_SERVER_URL+'restore_pass.php')
      .query({hash:hash,pass:sha1(pass)})
      .end((err, res) => {
        this.setState({showLoader:false})
        if(err) {
          console.error('Error, posiblemente no haya conexión con el servidor', err);
          return null;
        }
        if(res && res.text) {
          console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);          
          console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if(jsonRes.success==="false") {
            if(jsonRes.regError==="true") {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
            }
          }
          else {
            this.setState({contentType:"success"})
          }
        } else {
          console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
        }
      });    
  }


  render() {
    
    const { classes } = this.props;

    return (
      <Fragment>
        <PopUp back={this.closePopUp} logo={"Ingresar"} showLoader={this.state.showLoader} opened={this.state.opened}>
          {this.state.contentType==='regular' && <Fragment>
            <form>              
              <TextField
                id="outlined-password-input"
                label="Nueva contraseña"
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,                      
                  },
                }}
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                value={this.state.password}
                onChange={this.handleChange('password')}
              />     
            </form>
            
            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.restoreUser()}}>
                Cambiar contraseña
              </Button>
            </div>
          </Fragment>}
          {this.state.contentType==='success' && <Fragment>
            <div id="forgot-content">
                <p>Tu contraseña ha sido cambiada satisfactoriamente.</p>                
            </div>

            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.closePopUpWithAnim()}}>
                Regresar
              </Button>
            </div>
          </Fragment>}
        </PopUp>
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(Ingresar));