import SimpleCrypto from "./TulioCrypto";
//import SimpleCrypto from "simple-crypto-js";

const request = require("superagent");

const ClockManager = require("./ClockManager.js");

const sep = "]--[";

export class SesionManager {
  constructor() {
    this.idUser = 0;
    this.nameUser = "";
    this.lastCalvinInMilis = -1;
    this.CalvinFromServer = -1;
    this.mainScreen = null;
    this.cookieObject = {};
    this.hasVolume = 1;
    this.CalvinRemainedToShow = "--:--:--";
    this.rankingDataRetrieved = [false,false];
    this.rankingData = {0:{data:[],myPos:"---",myMaxScore:"---",myMinTime:"---"},1:{data:[],myPos:"---",myMaxScore:"---",myMinTime:"---"}};
    this.restoreCookies();

    this.checkVolumeCookie();

    this.cryp = null;

    this.hash = "";
    this.id_sesion_juego = 0;
  }

  checkVolumeCookie() {
    if (this.cookieObject["vol"] !== undefined) {
      this.setVolume(Number(this.cookieObject["vol"]));
    }
  }

  setVolume(val) {
    this.hasVolume = val;
    this.setCookie("vol", val);
  }

  //Funcion que devuelve un 20 pero sin tenerlo quemado en codigo para que no lo puedan hackear
  getCalvinToLaunch() {
    var localcryp = new SimpleCrypto("365bc3eb93d387b5417c40a8b78db6b7");
    ////console.log('cr:',localcryp.encrypt('20'))
    return localcryp.decrypt(
      "c57dce58d2c2480b4d5c4f4f694c00299fe314aba142c226ac42abd6c3015069X0fR8tcLDWgr2u90RDpfrQ=="
    );
    //SHA512: localcryp.decrypt("c2741384b1009d8d6a98f73c3d458045d02458d85175be95cc02878e3f01ba7fIzrRofvTHu5NoAyE90gGkw==");
    //SHA1: localcryp.decrypt("c57dce58d2c2480b4d5c4f4f694c00299fe314aba142c226ac42abd6c3015069X0fR8tcLDWgr2u90RDpfrQ==");
  }

  /*cookies:
    "idUser=36; nameUser=Carlos; mail=cbeltrangomez@gmail.com; hash=e00dbed51596d10976f2b63ecb449e25f252f982"
  */

  setCookie(reg, val) {
    this.cookieObject[reg] = val;
    document.cookie = reg + "=" + val;
  }

  deleteCookie(reg) {
    delete this.cookieObject[reg];
    document.cookie = reg + "=;expires=Thu, 01 Jan 1970 00:00:01 GMT;";
  }

  getCookie(reg) {
    return this.cookieObject[reg];
  }

  printCookies() {
    //console.log("-------------COOKIES");
    for (var key in this.cookieObject) {
      //console.log(key, " - ", this.cookieObject[key]);
    }
    //console.log("--------------------");
  }

  restoreCookies() {
    this.cookieObject = {};
    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i].trim();

      var indexEq = c.indexOf("=");
      var regAct = c.substr(0, indexEq);
      var valAct = c.substr(indexEq + 1);

      this.cookieObject[regAct] = valAct;
    }
  }

  checkLoggedUserInfo(jsonRes) {
    this.setUserData(
      jsonRes.nombre,
      jsonRes.apellido,      
      jsonRes.id,
      jsonRes.cedula,
      jsonRes.time_from_lastcode,
      jsonRes.localCalvin,
      jsonRes.allow_play_without_code
    );
  }

  tryRestoreSesion(callbackGoodSesion, callbackBadSesion) {

    let correo = this.getCookie("mail");
    let pass = this.getCookie("hash");

    //console.log('Obtiene de cookies',correo,pass);

    request
      .post(process.env.REACT_APP_SERVER_URL + "login_user.php")
      .query({ correo: correo, pass: pass })
      .end((err, res) => {
        if (err) {
          //console.log('LLEGA RESPUESTA A LOGIN',err,res);
          this.mainScreen.showAppError('Error, posiblemente el servidor no este respondiendo de manera correcta')
          return null;
        }
        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          //console.log(jsonRes, jsonRes["success"], jsonRes.success);
          if (jsonRes.success === "false") {
            this.closeSession();
            callbackBadSesion();
          } else {
            //console.log('jres',jsonRes);
            this.checkLoggedUserInfo(jsonRes);
            callbackGoodSesion();
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  }

  assignCode(code) {
    this.lastCalvinInMilis = 0;
    this.initRemainingCounter();
    this.mainScreen.updateGame();
  }

  regMainScreen(mainScreen) {
    this.mainScreen = mainScreen;
  }

  setUserData(
    nombre,
    apellido,
    id,
    cedula,
    lastCalvinInMilis,
    CalvinFromServer,
    allow_play_without_code
  ) {
    this.allow_play_without_code = allow_play_without_code;
    this.nameUser = nombre;    
    this.idUser = id;
    this.cedula = cedula;
    //console.log("-->", lastCalvinInMilis);
    this.lastCalvinInMilis = lastCalvinInMilis;
    this.CalvinFromServer = CalvinFromServer;
    this.setCookie("nameUser", nombre);
    this.setCookie("idUser", id);

    /*console.log(
      "-----------",
      this.allow_play_without_code,
      !this.allow_play_without_code
    );*/

    if (lastCalvinInMilis !== -1 && !this.allow_play_without_code) {
      this.initRemainingCounter();
    }

    this.mainScreen.setUserName(nombre);
    this.mainScreen.setUserLastName(apellido);
  }

  closeSession() {
    this.idUser = 0;
    this.nameUser = "";
    this.lastCalvinInMilis = -1;
    this.deleteCookie("idUser");
    this.deleteCookie("nameUser");
    this.deleteCookie("mail");
    this.deleteCookie("hash");
  }

  initRemainingCounter() {
    this.remainingClockManager = new ClockManager({ countdown: 86400 });
    this.remainingClockManager.setCalvinListener(
      this.onRemainingCounterInfo,
      this.onCalvinDepleted,
      this.onUserFake
    );
    this.remainingClockManager.start(this.lastCalvinInMilis * 1000);
  }

  onCalvinDepleted = () => {
    this.lastCalvinInMilis = -1;
    this.mainScreen.updateGame();
  };

  onUserFake = (prevCalvin, newCalvin) => {
    let str =
      "[UserCalvinChange]:prev(" +
      prevCalvin +
      "),new(" +
      newCalvin +
      "),diff(" +
      (newCalvin - prevCalvin) +
      ")";
    //console.log("Reportar", str);

    request
      .post(process.env.REACT_APP_SERVER_URL + "report.php")
      .query({ id_user: this.idUser, str_data: str })
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  };

  onRemainingCounterInfo = strCalvin => {
    this.mainScreen.updateRemainingCalvin(strCalvin);
  };

  validateEmail(email) {
    var index1 = email.indexOf("@");
    var index2 = email.lastIndexOf(".");
    
    if(index1===-1 || index2===-1) {
      return false;
    }

    if(index2<index1) {
      return false;
    }

    return true;    
  }

  /*validateEmail2(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }*/

  getCurrentCryTulio = () => {
    return this.currentGameTulio;
  }
  
  getDecrypTulio = (crypted) => {
    return Number(this.cryp.decrypt(crypted));
  }

  getCurrentTulio = () => {
    return Number(this.cryp.decrypt(this.currentGameTulio));
  }

  setCurrentTulio = (newTulio) => {
    this.collectMessage("addTulio",{tulio:newTulio});
    this.currentGameTulio = this.cryp.encrypt("" + newTulio);
  }

  getGameSession = (callback,juego = 1,sesion_prueba = 0) => {
    let id_user = this.idUser;

    request
      .post(process.env.REACT_APP_SERVER_URL + "get_game.php")
      .query({ id_user, juego, sesion_prueba })
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          if (jsonRes.success === "false") {
            this.closeSession();
          } else {
            this.id_sesion_juego = jsonRes.id_sesion_juego;
            this.message_id = 1;
            this.hash = jsonRes.hash;

            if (this.cryp === null) {
              this.cryp = new SimpleCrypto(this.hash);
            } else {
              this.cryp.setSecret(this.hash);
            }

            this.setCurrentTulio(0);

            callback();
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  }

  collectMessage(title, objMessage) {
    let objMsg = {};
    let msgStr = "[";

    if (title === "Init") {
      this.CalvinFirstLog = new Date().getTime();
    } else {
      objMessage.Calvin = new Date().getTime() - this.CalvinFirstLog;
    }

    for (var msg in objMessage) {
      if (objMessage.hasOwnProperty(msg)) {
        msgStr += msg + ":" + objMessage[msg] + sep;
      }
    }

    objMsg.id_sesion_juego = this.id_sesion_juego;
    objMsg.log_title = title;
    objMsg.log_order = this.message_id;
    objMsg.log_info = msgStr + new Date().toLocaleTimeString() + "]";

    this.message_id++;

    this.sendMessage(objMsg);
  }

  sendMessage(objMessage) {
    //let id_sesion_juego,log_title,log_order,log_info = {objMessage}

    //get_alive = send_log
    request
      .post(process.env.REACT_APP_SERVER_URL + "get_alive.php")
      .query(objMessage)
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          if (jsonRes.success === "false") {
            this.closeSession();
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  }

  endGame(additionalInfo) {
    let objMessage = {
      id_usuario: this.idUser,
      id_sesion_juego: this.id_sesion_juego,
      session_token: this.currentGameTulio
    };

    if(additionalInfo!==undefined) {
      objMessage.additionalInfo = additionalInfo;
    }

    let honeypot = this.getCurrentTulio();

    //get_alive_final = store_score
    request
      .post(process.env.REACT_APP_SERVER_URL + "get_alive_final.php")
      .query(objMessage)
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);
          //console.log('res endGame',jsonRes);
          if (jsonRes.success === "false") {
            this.mainScreen.closeSession();
          } else {
            if(jsonRes.wasTrial!=='true') {
              //console.log('Lo nuevo que llega de juego es',jsonRes.juego);
              this.mainScreen.refreshScores(jsonRes.juego);              
            }
          }
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });

    //get_alive_final = store_score
    request
      .post(process.env.REACT_APP_SERVER_URL + "report_score.php")
      .query({ id_user: this.idUser, score: honeypot })
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }
        if (res && res.text) {
        } else {
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  }

  askAndProcessRanking = (juego,verifyPos=true) => {

    return new Promise((resolve, reject) => {
      //get_alive_final = store_score
      request
        .post(process.env.REACT_APP_SERVER_URL+'get_ranking.php') 
        .query({juego})     
        .end((err, res) => {
          if(err) {
            console.error('Error, posiblemente no haya conexión con el servidor', err);
            return null;
          }
          if(res && res.text) {            
            let jsonRes = JSON.parse(res.text);          
            if(jsonRes.success==="false") {
              this.props.showAppError(jsonRes.msg);            
            }
            else {
              let amountData = jsonRes.scoresData.length;
              this.rankingDataRetrieved[juego-1] = true;
              //console.log('Tengo',this.rankingData,juego);
              this.rankingData[juego-1].data = [];
              
              for (var i = 0; i < amountData; i++) {
                var tempData = jsonRes.scoresData[i];
                
                if(verifyPos && tempData[0]==this.idUser) {
                  this.rankingData[juego-1].myPos = i+1;
                  this.rankingData[juego-1].myMaxScore = tempData[2];
                  this.rankingData[juego-1].myMinTime = tempData[3];

                  //console.log('Obtiene el myMinTime con ',tempData[3]);
                }              

                this.rankingData[juego-1].data.push(tempData)
              }
              
              //console.log(`Luego de consultar el juego ${juego} se tiene mi pos `,this.rankingData[juego-1].myPos,'con',this.rankingData[juego-1].myMaxScore);
              resolve();       
            }

          } else {
            console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
          }
        });    
    });
  }

 confirmUser = (token_confirm) => {
    let objMessage = {
      token_confirm
    };

    //get_alive_final = store_score
    request
      .post(process.env.REACT_APP_SERVER_URL + "confirm_user.php")
      .query(objMessage)
      .end((err, res) => {
        if (err) {
          console.error(
            "Error, posiblemente no haya conexión con el servidor",
            err
          );
          return null;
        }

        if (res && res.text) {
          //console.log("Llega la respuesta", res.text);
          let jsonRes = JSON.parse(res.text);

          if (jsonRes.success===false) {
            if(jsonRes.regError===true) {
              this.mainScreen.showAppError(jsonRes.msg);
            }
          } else {
            this.tryRestoreSesion(
              () => {
                this.mainScreen.setUserName(jsonRes.nombre);
              }, 
              () => {this.closeSession()}
            )
            //this.setUserData(jsonRes.nombre,jsonRes.id,-1,0,jsonRes.allow_play_without_code);
            this.mainScreen.showAppError(jsonRes.msg);
          }
        } 
        else {          
          console.error(
            "Error, posiblemente el servidor no este respondiendo correctamente",
            err
          );
        }
      });
  }

  fullscreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;
    this._fRequestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    this._fCancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;

    this._fRequestFullScreen.call(window.document.documentElement);
  }

  getAttempsInfo = (game) => {
    let objMessage = {
      id_user:this.idUser,
      game:game,
    };

    return new Promise((resolve, reject) => {
      request
        .post(process.env.REACT_APP_SERVER_URL + "get_attemps_by_game.php")
        .query(objMessage)
        .end((err, res) => {
          if (err) {
            console.error(
              "Error, posiblemente no haya conexión con el servidor",
              err
            );
            return null;
          }

          if (res && res.text) {
            let jsonRes = JSON.parse(res.text);
            //console.log("Llega la respuesta", jsonRes);
            resolve(jsonRes);

            /*if (jsonRes.success===false) {
              if(jsonRes.regError===true) {
                this.mainScreen.showAppError(jsonRes.msg);
              }
            } else {
              this.tryRestoreSesion(
                () => {
                  this.mainScreen.setUserName(jsonRes.nombre);
                }, 
                () => {this.closeSession()}
              )
              //this.setUserData(jsonRes.nombre,jsonRes.id,-1,0,jsonRes.allow_play_without_code);
              this.mainScreen.showAppError(jsonRes.msg);
            }*/
          } 
          else {          
            console.error(
              "Error, posiblemente el servidor no este respondiendo correctamente",
              err
            );
          }
        });
    });
  }

  getAllAttempsInfo = () => {
    let objMessage = {
      id_user:this.idUser,      
    };

    return new Promise((resolve, reject) => {
      request
        .post(process.env.REACT_APP_SERVER_URL + "get_attemps.php")
        .query(objMessage)
        .end((err, res) => {
          if (err) {
            console.error(
              "Error, posiblemente no haya conexión con el servidor",
              err
            );
            return null;
          }

          if (res && res.text) {
            let jsonRes = JSON.parse(res.text);
            //console.log("Llega la respuesta", jsonRes);
            resolve(jsonRes);           
          } 
          else {          
            console.error(
              "Error, posiblemente el servidor no este respondiendo correctamente",
              err
            );
          }
        });
    });
  }

}

export let sesionManager = new SesionManager();
