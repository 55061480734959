import React, { Component,Fragment } from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import PopUp from './PopUp'
import './Ingresar.css';

import { sesionManager } from './SesionManager'

const sha1 = require('js-sha1');
const request = require('superagent');


const styles = theme => ({  
    textField: {
      width: "100%"
    },

    cssLabel: {
      fontFamily: 'Titillium Web'
    },

    cssFocused: {
      //color: "#f8e926",
    },

    cssOutlinedInput: {
      fontFamily: 'Titillium Web'     
    },
    notchedOutline: {
      borderColor: "#f8e926",
    },

    cssCheck: {
      color: "#f8e926 !important",         
    },

    button: {
      margin: theme.spacing.unit,
      backgroundColor: "#b718e6",
      borderRadius: 30,
      '&:hover': {
        backgroundColor: "#691881",
      }
    },

    buttonReg: {
      margin: theme.spacing.unit,
      backgroundColor: "#109cea",
      borderRadius: 30,
    },
});

class Ingresar extends Component {
 
  state = {
    mail: '',
    password: '',
    showLoader:false,    
    contentType: 'regular', //forgetData, regular,restorePass,afterResponse
    opened: true,    
  };

  handleChange = name => event => {
    this.setState({
      [name]: event.target.value,
    });
  };  

  hitEnter = (ev) => {
    if (ev.key === 'Enter') {
      this.proceed();
      ev.preventDefault();
    }
  }

  proceed = () => {
    if(this.state.contentType==="restorePass") {
      this.recuperarPass();
    } else {
      this.logUser();        
    }
  }

  closePopUp = () => {
    this.props.back();
  }

  closePopUpWithAnim = () => {
    this.setState({opened:false})    
  }

  showContent = (contentType) => {
    this.setState({contentType: contentType})
  }

  logUser = () => {
    
    let correo = this.state.mail;
    let pass = this.state.password;
    
    let error = "";

    if(!sesionManager.validateEmail(correo)) {
      error = "Lo sentimos, el correo electronico no es valido";
    }
    
    if(correo.length===0||pass.length===0) {
      error = "Lo sentimos, todos los campos son obligatorios";
    }
    
    if(error!=="") {
      this.props.showAppError(error);
      return;      
    }
    
    this.setState({showLoader:true})

    request
      .post(process.env.REACT_APP_SERVER_URL+'login_user.php')
      .query({correo:correo,pass:sha1(pass)})
      .end((err, res) => {
        this.setState({showLoader:false})
        if(err) {
          console.error('Error, posiblemente no haya conexión con el servidor', err);
          return null;
        }
        if(res && res.text) {
          console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);          
          console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if(jsonRes.success==="false") {
            if(jsonRes.regError==="true") {
              if(jsonRes.errorType==="NO_CONFIRMED") {
                this.props.showNoConfirmed(correo);
              } else {
                this.props.showAppError(jsonRes.msg);                
              }
            } else {
              this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
            }
          }
          else {
            sesionManager.checkLoggedUserInfo(jsonRes);
            sesionManager.setCookie("mail",correo);
            sesionManager.setCookie("hash",sha1(pass));
            this.closePopUpWithAnim();
          }
        } else {
          console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
        }
      });    
  }

  recuperarPass() {

    let score = this.state.mail;
    
    let error = "";

    if(!sesionManager.validateEmail(score)) {
      error = "Lo sentimos, el correo electronico no es valido";
    }
    
    if(score.length===0) {
      error = "Lo sentimos, todos los campos son obligatorios";
    }
    
    if(error!=="") {
      this.props.showAppError(error);
      return;      
    }
    
    this.setState({showLoader:true})

    request
      .post(process.env.REACT_APP_SERVER_URL+'ask_restore_pass.php')
      .query({correo:score})
      .end((err, res) => {
        this.setState({showLoader:false})
        if(err) {
          console.error('Error, posiblemente no haya conexión con el servidor', err);
          return null;
        }
        if(res && res.text) {
          console.log('Llega la respuesta',res.text)
          let jsonRes = JSON.parse(res.text);          
          console.log(jsonRes,jsonRes["success"],jsonRes.success)
          if(jsonRes.success==="false") {
            if(jsonRes.regError==="true") {
              this.props.showAppError(jsonRes.msg);
            } else {
              this.props.showAppError("Ha ocurrido un error al intentar loguearte, si el error persiste contactate al correo "+process.env.REACT_APP_SUPORT_MAIL+"\n\n[error: "+jsonRes["msg"]+"]");
            }
          }
          else {            
            this.setState({contentType:"afterResponse"})
          }
        } else {
          console.error('Error, posiblemente el servidor no este respondiendo correctamente', err);          
        }
      });    
  }


  render() {
    
    const { classes } = this.props;

    return (
      <Fragment>
        <PopUp back={this.closePopUp} logo={"Ingresar"} showLoader={this.state.showLoader} opened={this.state.opened}>
          {this.state.contentType==='regular' && <Fragment>
            <form>
              <TextField
                id="outlined-email-input"
                label="Email"
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,                      
                  },
                }}
                type="email"
                name="email"
                autoComplete="email"
                margin="normal"
                variant="outlined"
                value={this.state.mail}
                onChange={this.handleChange('mail')}
              />

              <TextField
                id="outlined-password-input"
                label="Contraseña"
                className={classes.textField}
                onKeyPress={this.hitEnter}
                InputLabelProps={{
                  classes: {
                    root: classes.cssLabel,   
                    focused: classes.cssFocused,                   
                  },
                }}
                InputProps={{
                  classes: {
                    root: classes.cssOutlinedInput,                      
                  },
                }}
                type="password"
                autoComplete="current-password"
                margin="normal"
                variant="outlined"
                value={this.state.password}
                onChange={this.handleChange('password')}
              />  

              <div id="labelOlvido">               
                <button onClick={() => {/*this.showContent("forgetData")*/this.showContent("restorePass")}}>¿Olvidaste tu contraseña?</button>                            
              </div>          
            </form>
            
            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.logUser()}}>
                Ingresar
              </Button>
            </div>
          </Fragment>}
          {this.state.contentType==='forgetData' && <Fragment>
            <div id="forgot-content">
                <p>¡Ponte en contacto con nosotros y te ayudamos!</p>
                <p>
                  <a rel="noopener noreferrer" target="_blank" href={"mailto:"+process.env.REACT_APP_SUPORT_MAIL+"?subject=Quiero recuperar mi contraseña"}>{process.env.REACT_APP_SUPORT_MAIL}</a>
                </p>
            </div>

            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.showContent("regular")}}>
                Regresar
              </Button>
            </div>
          </Fragment>}
          {this.state.contentType==='restorePass' && <Fragment>
            <div id="restore-content">
                <form>
                  <TextField
                    id="outlined-email-input"
                    label="Email"
                    className={classes.textField}
                    onKeyPress={this.hitEnter}
                    InputLabelProps={{
                      classes: {
                        root: classes.cssLabel,   
                        focused: classes.cssFocused,                   
                      },
                    }}
                    InputProps={{
                      classes: {
                        root: classes.cssOutlinedInput,                      
                      },
                    }}
                    type="email"
                    name="email"
                    autoComplete="email"
                    margin="normal"
                    variant="outlined"
                    value={this.state.mail}
                    onChange={this.handleChange('mail')}
                  />                           
                </form>
            </div>

            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.proceed()}}>
                Recuperar contraseña
              </Button>
              <Button variant="contained" color="primary" className={classes.buttonReg} onClick={() => {this.showContent("regular")}}>
                Regresar
              </Button>
            </div>
          </Fragment>}
          {this.state.contentType==='afterResponse' && <Fragment>
            <div id="forgot-content">
                <p>Verifica tu correo electrónico para continuar con el proceso</p>                
            </div>

            <div id="buttonRegContainer">
              <Button variant="contained" color="primary" className={classes.button} onClick={() => {this.closePopUpWithAnim()}}>
                Regresar
              </Button>
            </div>
          </Fragment>}
        </PopUp>
      </Fragment>
    );
  }
}

export default (withStyles(styles,{ withTheme: true })(Ingresar));